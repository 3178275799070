import React from 'react'


import AdminActions from '../../../../actions/AdminActions.js'

import {Input} from '../../../../components/UI/index.js'
import {Alert, Button, Modal, P, H4} from '../../../../components/UI/index.js'

export default class RemovePopup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            reseller: {},
            name: '',
            loading: false,
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(reseller) {
        this.setState({isOpen: true, reseller})
    }

    close() {
        this.setState(this.initialState)
    }

    onClickConfirm() {
        const {reseller, name} = this.state
        const {viewArchived} = this.props

        if (reseller.name === name) {
            this.setState({loading: true, error: ''})

            AdminActions.toggleArchiveReseller(reseller._id, (err) => {
                if (err) {
                    this.setState({loading: false, error: err})
                } else {
                    AdminActions.getResellers(true, viewArchived)
                    this.close()
                }
            })
        } else {
            this.setState({error: 'De ingevulde naam komt niet overeen met de resellernaam.'})
        }
    }

    render() {
        const {isOpen, reseller, name, loading, error} = this.state

        return (
            <Modal show={isOpen} style={{width: 400}} onClose={this.close.bind(this)}>

                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>{reseller.isArchived ? 'Activeer' : 'Archiveer'} reseller</H4>

                    <div style={{flex: 1}}/>
                </div>

                <P style={{marginBottom: 30}}>
                    {`Let op! Reseller ${reseller.name} inclusief alle gebruikers worden ${reseller.isArchived ? 'geactiveerd' : 'gearchiveerd'}. Vul hieronder de resellernaam in om door te gaan.`}
                </P>

                <Input
                    label='Naam'
                    value={name}
                    onChange={(event) => this.setState({name: event.target.value})}
                />


                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button
                        variant='text'
                        onClick={this.close.bind(this)}
                    >
                        Annuleren
                    </Button>
                    <Button
                        variant='text'
                        loading={loading}
                        onClick={this.onClickConfirm.bind(this)}
                    >
                        Bevestigen
                    </Button>
                </div>
            </Modal>
        )
    }
}
