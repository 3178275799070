import moment from 'moment'

export default (reseller) => {
    const order = {
        date: moment().format('YYYY-MM-DD'),
        routeId: '',
        customer: '',
        contact: '',
        email: '',
        phone: '',

        addresses: [{
            type: 'pickup',
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                moment().format('HH:mm'),
            endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                moment().format('HH:mm')
        },
        {
            type: 'delivery',
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                moment().format('HH:mm'),
            endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                moment().format('HH:mm'),
            gpsRequired: false,
            signatureRequired: false,
            signatureNeighbors: false,
            statedAddressOnly: false,
            nameRequired: false,
            scanRequired: false,
            imageRequired: false,
            mailboxDelivery: false,
            minimumAge: false,
            idCheck: false
        }],
        colli: [],
        addressOrder: 'fixed',

        nrOfDeliveries: 0,
        autoRetour: false,
        nrOfDaysBeforeRetour: 7,
        retourAddress: {
            type: 'delivery',
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            instructions: '',
            email: '',
            phone: '',
            startTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                '09:00',
            endTime: reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                '17:00',
            gpsRequired: false,
            signatureRequired: false,
            signatureNeighbors: false,
            statedAddressOnly: false,
            nameRequired: false,
            scanRequired: false,
            imageRequired: false,
            mailboxDelivery: false,
            minimumAge: false,
            idCheck: false
        },

        monitorTemperature: false,
        sensorId: '',

        priceTableId: '',
        price: '0,00',
        feeIds: [],
        fees: [],
        productCodes: [],
        reference: '',

        notes: '',

        history: []
    }


    return JSON.parse(JSON.stringify(order))
}
