import React, {useEffect, useState} from 'react'

import {Panel, P, Button, ListItem, Colors, H3, Toggle} from '../../../../components/UI/index.js'
import EditVehicleTypeModal from '../../../../components/vehicles/EditVehicleTypeModal.js'
import guid from '../../../../../utils/guid.js'
import {newVehicleType} from '../../../../components/vehicles/newVehicle.js'
import useVehicleStore from '../../../../stores/VehicleStore.js'

export default function VehicleTypes({reseller, onChange}) {
    const [editVehicleModalOpen, setEditVehicleModalOpen] = useState(false)
    const [activeVehicleTypeIndex, setActiveVehicleTypeIndex] = useState(null)
    const [showArchived, setShowArchived] = useState(false)
    const vehicles = useVehicleStore((state) => state.vehicles)
    const getVehicles = useVehicleStore((state) => state.getVehicles)
    const updateVehicles = useVehicleStore((state) => state.updateVehicles)

    const archiveVehicles = (vehicleTypeId) => {
        const filteredVehicles = vehicles.filter(({typeId}) => typeId === vehicleTypeId).map((vehicle) => ({...vehicle, isArchived: true}))
        updateVehicles(filteredVehicles)
    }

    useEffect(() => {
        if (vehicles.length === 0) {
            getVehicles()
        }
    }, [])

    return (
        <Panel style={{width: 600, padding: 20}}>
            <H3>Voertuigsoorten</H3>

            <P>Hier kun je de verschillende soorten voertuigen beheren. Specifieke voertuigen maak je aan in het menu Voertuigen onder het hoofdmenu Beheer.</P>

            <Button
                onClick={() => {
                    setEditVehicleModalOpen(true)
                    setActiveVehicleTypeIndex(null)
                }}
                style={{marginTop: 12}}
                variant='outline'
                icon='mdi mdi-plus'
                label='Voertuigsoort toevoegen'
            />


            {reseller.settings.vehicleTypes.length > 0 && (
                <Panel style={{width: '100%', marginTop: 12}}>
                    {reseller.settings.vehicleTypes?.filter(({isArchived}) => !isArchived).map((vehicleType) => (
                        <ListItem
                            size='sm'
                            key={vehicleType._id}
                            style={{height: 48, padding: '0 12px'}}
                            onClick={() => {
                                setEditVehicleModalOpen(true)
                                setActiveVehicleTypeIndex(reseller.settings.vehicleTypes.findIndex(({_id}) => _id === vehicleType._id))
                            }}
                        >
                            {vehicleType.name}
                        </ListItem>
                    ))}
                    {showArchived && reseller.settings.vehicleTypes?.filter(({isArchived}) => isArchived).map((vehicleType) => (
                        <ListItem
                            size='sm'
                            key={vehicleType._id}
                            style={{height: 48, padding: '0 12px', color: Colors.textMedium}}
                            icon='mdi mdi-archive'
                            onClick={() => {
                                setEditVehicleModalOpen(true)
                                setActiveVehicleTypeIndex(reseller.settings.vehicleTypes.findIndex(({_id}) => _id === vehicleType._id))
                            }}
                        >
                            {vehicleType.name}
                        </ListItem>
                    ))}
                </Panel>
            )}

            <Toggle
                label='Toon gearchiveerde voertuigsoorten'
                checked={showArchived}
                onChange={(event) => setShowArchived(event.target.checked)}
                style={{marginTop: 12}}
            />

            <EditVehicleTypeModal
                open={editVehicleModalOpen}
                vehicleType={reseller.settings.vehicleTypes?.[activeVehicleTypeIndex] || newVehicleType()}
                onClose={() => setEditVehicleModalOpen(false)}
                onChange={(newVehicleType) => {
                    const vehicleTypes = reseller.settings.vehicleTypes || []

                    if (activeVehicleTypeIndex === null) {
                        newVehicleType._id = guid.generate()
                        vehicleTypes.push(newVehicleType)
                    } else {
                        vehicleTypes[activeVehicleTypeIndex] = newVehicleType
                    }

                    reseller.settings.vehicleTypes = vehicleTypes
                    onChange(reseller, 'vehicleTypes')
                    setEditVehicleModalOpen(false)
                }}
                onArchive={() => {
                    const vehicleTypes = reseller.settings.vehicleTypes

                    if (activeVehicleTypeIndex !== null) {
                        vehicleTypes[activeVehicleTypeIndex].isArchived = !vehicleTypes[activeVehicleTypeIndex].isArchived
                        onChange(reseller, 'vehicleTypes')
                        setEditVehicleModalOpen(false)
                        if (vehicleTypes[activeVehicleTypeIndex].isArchived) {
                            archiveVehicles(vehicleTypes[activeVehicleTypeIndex]._id)
                        }
                    }
                }}
                noArchivePossible={reseller.settings.vehicleTypes?.filter((vt) => !vt.isArchived)?.length <= 1}
            />
        </Panel>
    )
}
