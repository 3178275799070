import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, IconButton, P, Recurrence, Link, Input, Select, TextArea, Colors, Column, Row} from '../UI/index.js'


class Info extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            customer: '',
            contact: '',
            phone: '',
            email: '',
            notes: '',
            hover: false,
            loading: false,
            error: '',
            message: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    componentDidMount() {
        this.setState({
            customer: this.props.order.customer,
            contact: this.props.order.contact,
            phone: this.props.order.phone,
            email: this.props.order.email,
            notes: this.props.order.notes
        })
    }

    onChangeCustomer(event) {
        const {customers} = this.props

        const customer = customers[event.target.value]

        if (customer) {
            this.setState({
                customer: customer._id,
                contact: '',
                phone: customer.phone || '',
                email: customer.email || ''
            })
        }
    }

    onChangeContact(event) {
        const {customers} = this.props

        const customer = customers[this.state.customer]

        const contact = event.target.value

        const contactObject = _.find(customer?.contacts, {name: event.target.value})

        this.setState({
            contact,
            phone: contactObject?.phone || this.state.phone,
            email: contactObject?.email || this.state.email
        })
    }

    onClickGotoTrackTrace(event) {
        event.preventDefault()
        const {order} = this.props

        window.open(`/track&trace/${order.trackTrace}`)
    }


    save() {
        const {customer, contact, phone, email, notes} = this.state
        const {order} = this.props

        this.setState({loading: true, error: ''})

        OrderActions.editInfo(order._id, customer, contact, phone, email, notes, (err, order) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.setState({loading: false, hover: false, edit: false, error: ''})

                this.props.onUpdateOrder(order)
            }
        })
    }

    render() {
        const {customer, contact, phone, email, notes, hover, edit, error} = this.state
        const {customers, order} = this.props


        const customerName = order.customerAddress?.name || ''
        const senderName = order?.senderAddress?.name || ''

        if (edit) {
            return (
                <div style={{marginBottom: 12, width: '100%', padding: 6, background: Colors.grey20, borderBottom: `1px solid ${Colors.border}`}}>
                    <Row>
                        <Column>
                            <Select
                                label='Klant'
                                options={_.values(customers).map((customer) => ({value: customer._id, title: customer.name}))}
                                value={customer}
                                onChange={this.onChangeCustomer.bind(this)}
                            />
                        </Column>

                        <Column>
                            <Input
                                id='contact'
                                label='Contactpersoon'
                                suggestions={customers[customer]?.contacts?.map((contact) => contact.name) || []}
                                suggestionFilter
                                value={contact}
                                onChange={this.onChangeContact.bind(this)}
                            />

                            <IconButton
                                icon='mdi mdi-check'
                                onClick={this.save.bind(this)}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <Input
                                label='Emailadres'
                                type='email'
                                value={email}
                                onChange={(event) => this.setState({email: event.target.value})}
                            />
                        </Column>

                        <Column>
                            <Input
                                label='Telefoon'
                                type='phone'
                                value={phone}
                                onChange={(event) => this.setState({phone: event.target.value})}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column>
                            <TextArea
                                label='Opmerkingen'
                                value={notes}
                                onChange={(event) => this.setState({notes: event.target.value})}

                            />
                        </Column>
                    </Row>
                </div>
            )
        }

        return (
            <div style={{marginBottom: 12, width: '100%', padding: 6, borderBottom: `1px solid ${Colors.border}`}}>
                <div
                    style={{display: 'flex'}}
                >
                    <div
                        style={{flex: 1, display: 'flex'}}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex'}}>
                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-store'/>
                                <P>{`${senderName || ''}${senderName ? ` (${customerName})` : customerName}`}</P>
                            </div>

                            {order.contact &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-account'/>
                                    <P>{order.contact}</P>
                                </div>
                            }
                            {order.phone &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-phone'/>
                                    <P>{order.phone}</P>
                                </div>
                            }

                            {order.email &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-at'/>
                                    <P>{order.email}</P>
                                </div>
                            }
                        </div>

                        <div style={{width: 42, marginTop: -6}}>
                            {hover &&
                                <IconButton
                                    icon='mdi mdi-pencil'
                                    onClick={() => this.setState({edit: !edit})}
                                />
                            }
                        </div>
                    </div>

                    <div style={{flex: 1}}>
                        <div style={{display: 'flex'}}>
                            <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-calendar'/>
                            <P>{moment(order.date).format('DD-MM-YYYY')}</P>
                        </div>

                        <Recurrence
                            disabled={true}
                            rrule={order.rrule}
                        />

                        {order.notes &&
                            <div style={{display: 'flex'}}>
                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-message-outline'/>
                                <P>{order.notes}</P>
                            </div>
                        }

                        <div style={{display: 'flex'}}>
                            <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-web'/>
                            <Link onClick={this.onClickGotoTrackTrace.bind(this)}>{order.trackTrace}</Link>
                        </div>
                    </div>
                </div>

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }
            </div>


        )
    }
}

export default Info

