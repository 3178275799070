import defaultCustomerTrackTraceBody from './defaultCustomerTrackTraceBody.js'
import defaultCustomerCancelEmailBody from './defaultCustomerCancelEmailBody.js'
import defaultCustomerRetourConfirmationEmailBody from './defaultCustomerRetourConfirmationEmailBody.js'

export default () => {
    return JSON.parse(JSON.stringify(
        {
            name: '',
            synced: false,
            debtorCode: '',
            address: {
                name: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL'
            },
            phone: '',
            email: '',
            contacts: [],
            login: false,
            loginEmail: '',
            settings: {
                openingHours: {
                    monday: {
                        open: true
                    },
                    tuesday: {
                        open: true
                    },
                    wednesday: {
                        open: true
                    },
                    thursday: {
                        open: true
                    },
                    friday: {
                        open: true
                    },
                    saturday: {
                        open: false
                    },
                    sunday: {
                        open: false
                    }
                },
                usePickupDaysForRetourOrder: false,
                orders: {
                    defaultSigned: 'default',
                    defaultStatedAddressOnly: 'default',
                    nameRequired: 'default',
                    gpsRequired: 'default',
                    scanRequired: 'default',
                    allowAddCollo: 'default',
                    referenceRequired: false,
                    newOrderInstructions: '',
                    emailNotificationsReceiverOption: 'default',
                    emailNotificationsReceiver: [],
                    emailNotificationsCustomerOption: 'default',
                    emailNotificationsCustomer: [],
                    pickupNotPossibleReasons: ['default'],
                    deliveryNotPossibleReasons: ['default'],
                    pickupNotPossibleReasonsSpecifications: [],
                    deliveryNotPossibleReasonsSpecifications: []
                },
                parcels: {
                    selectCarrier: false,
                    emailTT: '',
                    disabledCarriers: [],
                    cycloonNoParcelsOnFriday: '',
                    cycloonDeliveredByBikeOnly: '',
                    DHLnoParcelsForBusinessAddress: '',
                    'DHL-MSGnoParcelsForBusinessAddress': '',
                    CycloonnoParcelsForBusinessAddress: '',
                    autoPickupOrder: false,
                    autoPickupPrice: '0,00',
                    coverAmount: '',
                    pickupDays: {
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: false,
                        sunday: false
                    },
                    sameDayCuttoff: '00:00',
                    sameDayPickupStart: '16:00',
                    sameDayPickupEnd: '17:00',
                    nextDayCuttoff: '17:00',
                    nextDayPickupStart: '17:00',
                    nextDayPickupEnd: '18:00',
                    priceTable: '',
                    priceTableAbroad: '',
                    defaultOptions: [],
                    defaultComment: '',
                    returnsVia: '',
                    senderAddress: {
                        name: '',
                        attention: '',
                        street: '',
                        nr: '',
                        addition: '',
                        street2: '',
                        postalCode: '',
                        city: '',
                        country: 'NL'
                    },
                    sendTTMails: false,
                    useVeloydTrackTraceTemplate: true,
                    trackTraceSenderName: '',
                    trackTraceReplyToEmail: '',
                    trackTraceEmailSubject: 'Track & Trace',
                    trackTraceEmailBody: defaultCustomerTrackTraceBody,
                    sendCancelEmails: false,
                    cancelEmailSenderName: '',
                    cancelEmailReplyToEmail: '',
                    cancelEmailSubject: 'Zending {barcode} geannuleerd',
                    cancelEmailBody: defaultCustomerCancelEmailBody,
                    sendRetourConfirmationEmails: false,
                    retourConfirmationEmailSenderName: '',
                    retourConfirmationEmailReplyToEmail: '',
                    retourConfirmationEmailSubject: 'Retourlabel',
                    retourConfirmationEmailBody: defaultCustomerRetourConfirmationEmailBody,
                    returnPortalActive: false,
                    returnPeriod: 14,
                    returnAddress: {
                        name: '',
                        attention: '',
                        street: '',
                        nr: '',
                        addition: '',
                        street2: '',
                        postalCode: '',
                        city: '',
                        country: 'NL'
                    },
                    returnPolicyURL: '',
                    returnShippingCosts: {
                        DHL: {
                            enabled: false,
                            value: '0,00'
                        },
                        GLS: {
                            enabled: false,
                            value: '0,00'
                        },
                        PostNL: {
                            enabled: false,
                            value: '0,00'
                        }

                    },
                    returnReasons: [],
                    returnRefundMethods: [],
                    mollie: {
                        active: false,
                        accessToken: '',
                        expireDate: '',
                        refreshToken: '',
                        websiteProfiles: []
                    },
                    sizes: []
                },
                webshops: {
                    lightspeed: {},
                    shopify: {},
                    woocommerce: {},
                    ccvshop: {},
                    bolcom: {},
                    mijnwebwinkel: {},
                    shoptrader: {},
                    exactOnline: {},
                    magento: {},
                    shopware: {},
                    prestashop: {}
                },
                apiKey: '',
                webhookURL: '',
                parcelWebhookURL: '',
                webhookAuthHeader: '',
                mfaRequired: false
            },
            notes: ''
        }
    ))
}
