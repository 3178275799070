// @ts-check

import React from 'react'


import {Input, Toggle, Select, Panel, ReasonsSettings, P} from '../../../../components/UI/index.js'
import {Divider, H3, H6} from '../../../../components/UI/index.js'

export default class OrderSettings extends React.Component {
    constructor(props) {
        super(props)
    }

    onChangeValue(key, parser, event) {
        if (!event && parser) {
            event = parser
            parser = null
        }
        const reseller = {...this.props.reseller}

        if (parser) {
            reseller.settings[key] = parser(event.target.value)
        } else {
            reseller.settings[key] = event.target.value
        }

        this.props.onChange(reseller)
    }

    onSaveValue(key, parser, event) {
        if (!event && parser) {
            event = parser
            parser = null
        }

        const {reseller, onChange} = this.props

        if (event && event.target) {
            if (parser) {
                reseller.settings[key] = parser(event.target.value)
            } else {
                reseller.settings[key] = event.target.value
            }
        }

        onChange(reseller, key)
    }

    onChangeChecked(key, event) {
        const reseller = {...this.props.reseller}
        reseller.settings[key] = event.target.checked
        this.props.onChange(reseller, key)
    }

    render() {
        /**
         * @type {Readonly<{reseller: Reseller}>}
         */
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>

                <H3>Opties</H3>


                <H6>Algemeen</H6>
                <Divider/>
                {!reseller.isShop &&
                <>
                    <Toggle
                        label='Scannen op hub'
                        info='Bezorgingen aangemeld door klanten krijgen de status Verwacht. Zodra deze op de hub arriveren moeten ze gescand worden voordat ze bezorgd kunnen worden.'
                        checked={reseller.settings.useExpectedOrders}
                        onChange={this.onChangeChecked.bind(this, 'useExpectedOrders')}
                    />

                    <H6 style={{marginTop: 48}}>Rit opties</H6>
                    <Divider/>

                    <Toggle
                        label='Standaard met handtekening'
                        info='Bezorgingen worden aangemaakt met de optie Handtekening, deze instellingen kan op klantniveau worden overschreven.'
                        checked={reseller.settings.defaultSigned}
                        onChange={this.onChangeChecked.bind(this, 'defaultSigned')}
                    />

                    <Toggle
                        label='Standaard naam voor ontvangst'
                        info='Bij bezorgen dient altijd een naam ingevuld te worden om de rit af te ronden, deze instellingen kan op klantniveau worden overschreven.'
                        checked={reseller.settings.nameRequired}
                        onChange={this.onChangeChecked.bind(this, 'nameRequired')}
                    />

                    <Toggle
                        label='Standaard niet bij buren'
                        info='Bezorgingen worden aangemaakt met de optie Niet bij buren, deze instellingen kan op klantniveau worden overschreven.'
                        checked={reseller.settings.defaultStatedAddressOnly}
                        onChange={this.onChangeChecked.bind(this, 'defaultStatedAddressOnly')}
                    />

                    <Toggle
                        label='GPS verplicht'
                        info='Ritten worden aangemaakt met de optie GPS verplicht, deze instellingen kan op klantniveau worden overschreven.'
                        checked={reseller.settings.gpsRequired}
                        onChange={this.onChangeChecked.bind(this, 'gpsRequired')}
                    />

                    <Toggle
                        label='Scan verplicht'
                        info='Ritten worden aangemaakt met de optie Scan verplicht, deze instellingen kan op klantniveau worden overschreven.'
                        checked={reseller.settings.scanRequired}
                        onChange={this.onChangeChecked.bind(this, 'scanRequired')}
                    />

                    <Toggle
                        label='Foto verplicht'
                        info='Ritten worden aangemaakt met de optie Foto verplicht, deze instellingen kan op klantniveau worden overschreven.'
                        checked={reseller.settings.imageRequired}
                        onChange={this.onChangeChecked.bind(this, 'imageRequired')}
                    />

                    <Select
                        label='Collo toevoegen toestaan'
                        info='Mag de Koerier colli toevoegen bij het adres. Deze instelling kan op klant- en ritniveau worden overschreven.'
                        options={[
                            {title: 'Ja', value: 'yes'},
                            {title: 'Alleen bij bezorgen', value: 'deliver'},
                            {title: 'Alleen bij ophalen', value: 'pickup'},
                            {title: 'Nee', value: ''}
                        ]}
                        value={reseller.settings.allowAddCollo}
                        onChange={this.onChangeValue.bind(this, 'allowAddCollo')}
                        onBlur={this.onSaveValue.bind(this, 'allowAddCollo')}
                    />

                    <H6 style={{marginTop: 48}}>Ophalen niet mogelijk redenen</H6>
                    <Divider/>
                    <P>
                        Kies hieronder welke redenen er gekozen kunnen worden wanneer het ophalen niet mogelijk is.
                        Daarnaast kunnen specificaties worden aangemaakt die gekozen kunnen worden als toelichting bij een reden.
                    </P>

                    <ReasonsSettings
                        reseller={reseller}
                        onChange={this.props.onChange}
                        type='pickup'
                    />


                    <H6 style={{marginTop: 48}}>Bezorgen niet mogelijk redenen</H6>
                    <Divider/>
                    <P>
                        Kies hieronder welke redenen er gekozen kunnen worden wanneer het bezorgen niet mogelijk is.
                        Daarnaast kunnen specificaties worden aangemaakt die gekozen kunnen worden als toelichting bij een reden.
                    </P>

                    <ReasonsSettings
                        reseller={reseller}
                        onChange={this.props.onChange}
                        type='delivery'
                    />

                    <H6 style={{marginTop: 48}}>Koerier app</H6>
                    <Divider/>

                    <Toggle
                        style={{marginBottom: 10}}
                        label='Colli scan verplicht voor starten van route'
                        checked={reseller.settings.scanRequiredBeforeStart}
                        onChange={this.onChangeChecked.bind(this, 'scanRequiredBeforeStart')}
                    />

                    <Toggle
                        style={{marginBottom: 10}}
                        label='Waarschuwing afwijking bezorgadres'
                        info='Als de afstand meer afwijkt dan de hieronder ingevulde waarde dan krijgt de koerier een waarschuwing.'
                        checked={reseller.settings.deliveryRadiusWarning}
                        onChange={this.onChangeChecked.bind(this, 'deliveryRadiusWarning')}
                    />

                    <Input
                        type='number'
                        label='Maximale afwijking bezorgadres (meters)'
                        min={0}
                        value={reseller.settings.maxDeliveryRadius || ''}
                        onChange={this.onChangeValue.bind(this, 'maxDeliveryRadius')}
                        onBlur={this.onSaveValue.bind(this, 'maxDeliveryRadius')}
                        disabled={!reseller.settings.deliveryRadiusWarning}
                    />

                    <Select
                        label='Afwijken van route volgorde'
                        info=''
                        value={reseller.settings.nextStopWarning}
                        onChange={this.onSaveValue.bind(this, 'nextStopWarning')}
                    >
                        <option value=''>Toestaan zonder waarschuwing</option>
                        <option value='warning'>Toestaan met waarschuwing</option>
                        <option value='notallowed'>Niet toestaan</option>
                    </Select>
                </>
                }

                <H6 style={{marginTop: 48}}>Routeplanning</H6>
                <Divider/>

                <Select
                    label='Standard voertuig'
                    value={reseller.settings.defaultVehicle}
                    onChange={this.onSaveValue.bind(this, 'defaultVehicle')}
                    options={reseller.settings.vehicleTypes.filter((v) => !v.isArchived).map(({_id, name, icon}) => ({value: _id, title: name, icon: `mdi mdi-${icon}`}))}
                />

                {false &&
                <>
                    <Toggle
                        label='Snelheid handmatig instellen'
                        checked={reseller.settings.customSpeed}
                        onChange={this.onChangeChecked.bind(this, 'customSpeed')}
                    />

                    {reseller.settings.customSpeed &&
                    <Input
                        type='number'
                        label='Afwijking gemiddelde snelheid'
                        noPlaceholder={true}
                        placeholder={0}
                        value={reseller.settings.speedDifference}
                        onChange={this.onChangeValue.bind(this, 'speedDifference', parseInt)}
                        onBlur={this.onSaveValue.bind(this, 'speedDifference', parseInt)}
                        append='%'
                    />
                    }
                </>

                }

                <Toggle
                    label='Optimaliseren voor tijdvakken'
                    info='Als deze optie is aangevinkt dan wordt de route geoptimaliseerd voor tijdvakken. Dit betekent dat de routeplanner rekening houdt met de tijdvakken die zijn ingesteld bij de klanten.'
                    checked={reseller.settings.optimizeForTimeslots}
                    onChange={this.onChangeChecked.bind(this, 'optimizeForTimeslots')}
                />

                <Toggle
                    label='Route aanpasbaar door koerier'
                    info='Als deze optie is aangevinkt dan kan de koerier de route aanpassen in de koerier app. Deze instelling kan op route niveau worden overschreven.'
                    checked={reseller.settings.adjustableByMessenger}
                    onChange={this.onChangeChecked.bind(this, 'adjustableByMessenger')}
                />
            </Panel>
        )
    }
}

