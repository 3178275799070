import React from 'react'


import Badge from './Badge.js'
import S2 from './fonts/S2.js'
import HTMLEditor from './HTMLEditor.js'

class MailTemplate extends React.Component {
    onChangeTemplate(event) {
        const {onChange} = this.props
        onChange(event)
    }

    onClickAddTag(tag) {
        let {value, onChange} = this.props
        value += `{${tag}}`
        onChange({target: {value: value}})
    }

    render() {
        const {value, tags} = this.props

        return (
            <div style={{marginBottom: 12}}>
                <HTMLEditor
                    value={value || ''}
                    onChange={this.onChangeTemplate.bind(this)}
                />
                <S2>
                    Klik op onderstaande tags om deze gebruiken in je bericht. Bij het versturen van de mail worden deze tags vervangen door gegevens van de zending.
                </S2>

                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 15, marginBottom: 12}}>
                    {tags && tags.map((tag, index) => {
                        return (
                            <Badge
                                key={index}
                                style={{marginRight: 6, marginBottom: 5, cursor: 'pointer'}}
                                onClick={this.onClickAddTag.bind(this, tag)}
                            >
                                {tag}
                            </Badge>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default (MailTemplate)
