import React from 'react'

import Reflux from 'reflux-react-16'
import {Route} from 'react-router-dom'

import LoginStore from '../../../stores/LoginStore.js'
import LoginActions from '../../../actions/LoginActions.js'
import AddressStore from '../../../stores/AddressStore.js'

import {Alert, Button, Panel, ListItem} from '../../../components/UI/index.js'
import AccountSettings from './AccountSettings.js'
import ParcelSettings from './ParcelSettings.js'
import CommunicationSettings from './CommunicationSettings.js'
import ReturnSettings from './ReturnSettings.js'
import ConnectionSettings from './Connections/Layout.js'
import AddressBookSetings from './AddressBookSettings.js'


class Settings extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            saving: false,
            saved: false,
            error: ''
        }

        this.stores = [LoginStore, AddressStore]
    }

    componentDidMount() {
        const {history} = this.props

        if (history.location.pathname === '/klant/instellingen' || history.location.pathname === '/klant/instellingen/') {
            history.replace('/klant/instellingen/bedrijfsgegevens')
        }
    }

    componentDidUpdate() {
        const {history} = this.props

        if (history.location.pathname === '/klant/instellingen' || history.location.pathname === '/klant/instellingen/') {
            history.replace('/klant/instellingen/bedrijfsgegevens')
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount.call(this)
        clearTimeout(this.state.timeout)
    }

    updateUser(user, save) {
        this.setState({user, saved: false}, () => {
            if (save) {
                this.saveSettings()
            }
        })
    }

    saveSettings(event) {
        event?.preventDefault()

        this.setState({saving: true}, () => {
            setTimeout(() => { // wait for Editor blur onChange
                const {user} = this.state

                LoginActions.updateUserSettings(user, (err) => {
                    if (err) {
                        this.setState({error: err, saving: false})
                    } else {
                        const timeout = setTimeout(() => {
                            this.setState({saved: false})
                        }, 5000)

                        this.setState({saving: false, saved: true, error: '', timeout})
                    }
                })
            }, 100)
        })
    }

    onNavigate(link) {
        const {history} = this.props
        history.push(link)
    }

    render() {
        const {user, saving, saved, error, addressBook} = this.state
        const {reseller, history} = this.props
        const {pathname} = history.location

        return (
            <div style={{display: 'flex', justifyContent: 'flex-start', minHeight: '100%'}}>
                <Panel style={{width: 300, borderTop: 'none'}}>
                    <ListItem
                        active={pathname === '/klant/instellingen/bedrijfsgegevens'}
                        icon='mdi mdi-domain'
                        onClick={this.onNavigate.bind(this, '/klant/instellingen/bedrijfsgegevens')}
                    >
                        Bedrijfsgegevens
                    </ListItem>
                    <ListItem
                        active={pathname === '/klant/instellingen/zendingen'}
                        icon='mdi mdi-package-variant-closed'
                        onClick={this.onNavigate.bind(this, '/klant/instellingen/zendingen')}
                    >
                        Zendingen
                    </ListItem>
                    <ListItem
                        active={pathname === '/klant/instellingen/emailnotificaties'}
                        icon='mdi mdi-email'
                        onClick={this.onNavigate.bind(this, '/klant/instellingen/emailnotificaties')}
                    >
                        Email notificaties
                    </ListItem>
                    {reseller.settings.parcels.products?.Retour && Object.keys(reseller.settings.parcels.products?.Retour).some((carrier) => reseller.settings.parcels.products?.Retour?.[carrier]) &&
                    <ListItem
                        active={pathname === '/klant/instellingen/retouren'}
                        icon='mdi mdi-arrow-u-left-top'
                        onClick={this.onNavigate.bind(this, '/klant/instellingen/retouren')}
                    >
                        Retouren
                    </ListItem>
                    }
                    <ListItem
                        active={pathname === '/klant/instellingen/koppelingen'}
                        icon='mdi mdi-link-variant'
                        onClick={this.onNavigate.bind(this, '/klant/instellingen/koppelingen')}
                    >
                        Koppelingen
                    </ListItem>

                    {pathname.indexOf('/klant/instellingen/koppelingen') !== -1 &&
                        <>
                            <ListItem
                                style={{paddingLeft: 50}}
                                active={pathname === '/klant/instellingen/koppelingen/api'}
                                icon='mdi mdi-api'
                                onClick={this.onNavigate.bind(this, '/klant/instellingen/koppelingen/api')}
                            >
                                API
                            </ListItem>
                            <ListItem
                                style={{paddingLeft: 50}}
                                active={pathname === '/klant/instellingen/koppelingen/webshops'}
                                icon='mdi mdi-cart'
                                onClick={this.onNavigate.bind(this, '/klant/instellingen/koppelingen/webshops')}
                            >
                                Webshops
                            </ListItem>
                        </>
                    }

                    <ListItem
                        active={pathname === '/klant/instellingen/adresboek'}
                        icon='mdi mdi-book-account-outline'
                        onClick={this.onNavigate.bind(this, '/klant/instellingen/adresboek')}
                    >
                        Adresboek
                    </ListItem>
                </Panel>

                <div style={{height: '100%', overflow: 'auto', width: '100%'}}>
                    {saved && <Alert onClose={() => this.setState({saved: false})} style={{position: 'fixed', zIndex: 100, width: 'calc(100vw - 300px)'}} variant="success">Je wijzigingen zijn opgeslagen</Alert>}
                    {error && <Alert onClose={() => this.setState({error: ''})} style={{position: 'fixed', zIndex: 100, width: 'inherit'}} variant="error">{error}</Alert>}

                    <div style={{margin: 30}}>
                        <Route path="/klant/instellingen/bedrijfsgegevens" render={(props) => <AccountSettings reseller={reseller} user={user} onChange={this.updateUser.bind(this)}/>}/>
                        <Route path="/klant/instellingen/zendingen" render={(props) => <ParcelSettings reseller={reseller} user={user} onChange={this.updateUser.bind(this)}/>}/>
                        <Route path="/klant/instellingen/emailnotificaties" render={(props) => <CommunicationSettings location={props.location} reseller={reseller} user={user} onChange={this.updateUser.bind(this)}/>}/>
                        {reseller.settings.parcels.products?.Retour && Object.keys(reseller.settings.parcels.products?.Retour).some((carrier) => reseller.settings.parcels.products?.Retour?.[carrier]) &&
                            <Route path="/klant/instellingen/retouren" render={(props) => <ReturnSettings reseller={reseller} user={user} onChange={this.updateUser.bind(this)} history={history}/>}/>
                        }
                        <Route path="/klant/instellingen/koppelingen" render={(props) => <ConnectionSettings reseller={reseller} user={user} history={history} onChange={this.updateUser.bind(this)}/>}/>
                        <Route path="/klant/instellingen/adresboek" render={(props) => <AddressBookSetings reseller={reseller} user={user} addressBook={addressBook}/>}/>


                        {history.location.pathname !== '/klant/instellingen/adresboek' && history.location.pathname !== '/klant/instellingen/koppelingen/webshops' &&
                            <Button
                                style={{marginTop: 15, width: 'fit-content'}}
                                loading={saving}
                                onClick={this.saveSettings.bind(this)}
                            >
                                Opslaan
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default (Settings)
