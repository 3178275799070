import React, {Component} from 'react'
import bwipjs from 'bwip-js'

import {Alert, Button, Colors, H5, Input, Link, P, RadioButton} from '../../components/UI/index.js'

export default class Setup2FA extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mfaChoice: '',
            mfaEmail: '',
            error: undefined,
            emailError: null,
            mfaLink: `otpauth://totp/Veloyd:${props.email}?secret=${props.MFAKey}&issuer=Veloyd`,
            isMobile: window.matchMedia('(max-width: 768px)').matches
        }
    }

    componentDidMount() {
        const {email, MFAKey} = this.props

        if (email && MFAKey) {
            const svg = bwipjs.toSVG({
                bcid: 'qrcode',
                text: `otpauth://totp/Veloyd:${email}?secret=${MFAKey}&issuer=Veloyd`
            })
            if (this.qrcodeRef) {
                this.qrcodeRef.innerHTML = svg
            }
        }
    }

    checkContinuePossible() {
        const {mfaEmail, mfaChoice} = this.state
        const {email} = this.props
        this.setState({emailError: null})

        if (!mfaChoice) {
            this.setState({error: 'Er is geen methode uitgekozen.'})
        }

        if (mfaChoice !== 'email') {
            return true
        }

        if (!mfaEmail) {
            this.setState({emailError: 'Er is geen email voor tweestapsverificatie ingevoerd.'})
            return false
        }

        if (mfaEmail.toLowerCase().trim() === email.toLowerCase().trim()) {
            this.setState({emailError: 'E-mailadres voor 2FA kan niet hetzelfde zijn als je login e-mailadres.'})
            return false
        }

        return true
    }


    render() {
        const {mfaChoice, mfaEmail, error, emailError, mfaLink, isMobile} = this.state
        const {MFAKey, onContinue, resellerName, branding} = this.props

        const selectorStyle = (active) => ({
            border: active ? `1px solid ${Colors.buttonSolid}` : `1px solid ${Colors.grey40}`,
            minWidth: 250,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: Colors.backgroundWhite,
            borderRadius: 8,
            cursor: 'pointer',
            padding: 10,
            whiteSpace: 'initial'
        })

        const submitButton = (
            <Button
                variant='solid'
                color={branding?.color || Colors.brandColor}
                style={{
                    display: mfaChoice ? 'block' : 'none',
                    marginBottom: 12,
                    width: '75%',
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0
                }}
                onClick={() => {
                    const continueIsPossible = this.checkContinuePossible()
                    continueIsPossible && onContinue(mfaChoice, mfaEmail)
                }}
            >
                Bevestigen
            </Button>
        )

        return (
            <div>
                <P style={{width: '75%', margin: 'auto', textAlign: 'center'}}>
                    Voor dit account is tweestapsverificatie (2FA) verplicht door {resellerName}. Je kan kiezen om 2FA te activeren via een authenticator app of via een tweede emailadres.

                    <br/>
                    <br/>

                    Selecteer de gewenste methode:
                </P>

                <div
                    style={{
                        marginTop: 24,
                        display: 'flex',
                        gap: 16,
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        ...(isMobile) && {width: '95%', marginLeft: 'auto', marginRight: 'auto'}
                    }}
                >
                    <RadioButton
                        label={
                            <div style={selectorStyle(mfaChoice === 'app')}>
                                <H5 style={{marginBottom: 10}}>Authenticator app (aanbevolen)</H5>

                                <P style={{textAlign: 'center', marginBottom: 10}}>Een authenticator app kun je vinden in de app store van je telefoon. Scan de QR code met de authenticator app en volg de stappen zoals aangegeven op je telefoon. Je hebt de authenticator app nodig elke keer dat je in Veloyd wil inloggen.</P>

                                <div style={{width: 200, display: mfaChoice === 'app' ? 'block' : 'none'}} ref={(ref) => this.qrcodeRef = ref}/>

                                {mfaChoice === 'app' &&
                                    <>
                                        <P style={{textAlign: 'center', marginTop: 5, marginBottom: 5}}>Scan de QR code met de authenticator app en volg de stappen zoals aangegeven op je telefoon.</P>

                                        <P style={{marginTop: 5, marginBottom: 5}}>of</P>

                                        <P style={{marginTop: 5, marginBottom: 5}}>
                                            Via mobiel:{' '}
                                            <Link
                                                onClick={() => {
                                                    window.open(mfaLink, '_blank')
                                                }}
                                                style={{marginTop: 10, marginBottom: 10, display: 'inline'}}
                                            >
                                                open direct de authenticator app
                                            </Link>
                                        </P>

                                        <P style={{marginTop: 5, marginBottom: 5}}>of</P>

                                        <P style={{marginTop: 5, marginBottom: 10, textAlign: 'center'}}>
                                            Open de app en vul onderstaande code in:
                                            <pre style={{whiteSpace: 'unset', margin: 0}}>
                                                {MFAKey}
                                            </pre>
                                        </P>

                                        {submitButton}
                                    </>
                                }
                            </div>
                        }
                        checked={mfaChoice === 'app'}
                        style={{height: 'unset', flexDirection: 'column-reverse', gap: 10, flex: 1}}
                        labelStyle={{margin: 0}}
                        onChange={() => {
                            this.setState({mfaChoice: 'app', error: '', emailError: null})
                        }}
                        clickableLabel
                        hideDot
                    />

                    <RadioButton
                        label={
                            <div style={selectorStyle(mfaChoice === 'email')}>
                                <H5 style={{marginBottom: 10}}>E-mail</H5>

                                <P style={{textAlign: 'center', width: '75%'}}>Vul een e-mailadres is in wat anders is dan het e-mailadres waarmee je in Veloyd inlogd. Authenticatie codes zullen naar dit e-mailadres gestuurd worden.</P>
                                {mfaChoice === 'email' &&
                                <>
                                    <Input
                                        style={{marginTop: 12, width: '75%'}}
                                        label='Email'
                                        value={mfaEmail}
                                        onChange={(event) => this.setState({mfaEmail: event.target.value})}
                                        type='email'
                                        info='Dit email adres moet anders zijn dan je login email.'
                                        isInvalid={!!emailError}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault()
                                            }
                                        }}
                                    />

                                    {emailError &&
                                        <Alert
                                            style={{
                                                marginBottom: 12
                                            }}
                                            variant='error'
                                        >
                                            {emailError}
                                        </Alert>
                                    }

                                    {submitButton}
                                </>
                                }

                            </div>
                        }
                        checked={mfaChoice === 'email'}
                        style={{height: 'unset', flexDirection: 'column-reverse', gap: 10, flex: 1}}
                        labelStyle={{margin: 0}}
                        onChange={() => {
                            this.setState({mfaChoice: 'email'})
                        }}
                        clickableLabel
                        hideDot
                    />

                </div>

                {error &&
                    <Alert
                        style={{
                            marginBottom: 12,
                            ...(isMobile) && {width: '95%', marginLeft: 'auto', marginRight: 'auto'}
                        }}
                        variant='error'
                    >
                        {error}
                    </Alert>
                }
            </div>
        )
    }
}
