import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import {Colors, Editor, EmailPreview, H3, Input, Toggle, Panel, Tabs, Tab} from '../../../../components/UI/index.js'

import trackTraceEmailTemplate from '../../../../../server/functions/mail/templates/trackTraceEmailTemplate.js'
import cancelEmailTemplate from '../../../../../server/functions/mail/templates/cancelEmailTemplate.js'


class CustomerTrackTraceEmail extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            view: 'trackTrace'
        }
    }

    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }

    render() {
        const {reseller} = this.props
        const {view} = this.state

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Track & Trace Email</H3>

                <Tabs value={view} onChange={(event) => this.setState({view: event.target.value})}>
                    <Tab value='trackTrace'>Track & Trace</Tab>
                    <Tab value='cancelEmail'>Geannuleerd</Tab>
                </Tabs>

                {view === 'trackTrace' &&
                <>
                    <Toggle
                        label='Verstuur Track & Trace email'
                        checked={reseller.settings.parcels.trackTrace.sendTTMails}
                        onChange={this.onChangeChecked.bind(this, 'parcels.trackTrace.sendTTMails')}
                    />

                    <Input
                        label='Afzender naam'
                        placeholder={reseller.settings?.accountName || reseller.name}
                        value={reseller.settings.parcels.trackTrace.senderName}
                        onChange={this.onChangeValue.bind(this, 'parcels.trackTrace.senderName')}
                        onBlur={this.onSaveValue.bind(this, 'parcels.trackTrace.senderName')}
                    />

                    <Input
                        label='Afzender emailadres'
                        placeholder={(reseller.settings.useOwnSMTP && reseller.settings.smtpUser) || reseller.settings.companyAddress.email || 'noreply@veloyd.nl'}
                        value={reseller.settings.parcels.trackTrace.replyToEmail}
                        onChange={this.onChangeValue.bind(this, 'parcels.trackTrace.replyToEmail')}
                        onBlur={this.onSaveValue.bind(this, 'parcels.trackTrace.replyToEmail')}
                    />

                    <br/>
                    <Editor
                        label='Onderwerp'
                        as='input'
                        tags={['bedrijfsnaam', 'klant', 'naam', 'referentie', 'vervoerder']}
                        value={reseller.settings.parcels.trackTrace.emailSubject}
                        onChange={this.onSaveValue.bind(this, 'parcels.trackTrace.emailSubject')}
                    />

                    <Editor
                        label='Bericht'
                        tags={['bedrijfsnaam', 'klant', 'naam', 'referentie', 'vervoerder']}
                        value={reseller.settings.parcels.trackTrace.emailBody}
                        onChange={this.onSaveValue.bind(this, 'parcels.trackTrace.emailBody')}
                    />

                    <div style={{position: 'absolute', top: 90, left: 1100, width: 650}}>
                        <EmailPreview
                            type='trackTrace'
                            html={trackTraceEmailTemplate({
                                logo: reseller.settings.logo || '/images/logoPlaceholder.png',
                                message: reseller.settings.parcels.trackTrace.emailBody?.replace(/\n/g, '<br/>') || '',
                                color: reseller.settings.color || Colors.brandColor,
                                parcel: {
                                    customerName: 'Klant',
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                },
                                addressHeader: 'Bezorgadres',
                                address: {
                                    name: 'John Doe',
                                    attention: '',
                                    street: 'Kastanjelaan',
                                    nr: '400',
                                    addition: '',
                                    street2: '',
                                    postalCode: '5616 LZ',
                                    city: 'Eindhoven',
                                    country: 'NL'
                                }

                            })}
                        />
                    </div>
                </>
                }

                {view === 'cancelEmail' &&
                <>
                    <Toggle
                        label='Verstuur geannuleerd email'
                        checked={reseller.settings.parcels.cancelEmail.sendCancelEmails}
                        onChange={this.onChangeChecked.bind(this, 'parcels.cancelEmail.sendCancelEmails')}
                    />

                    <Input
                        label='Afzender naam'
                        placeholder={reseller.settings?.accountName || reseller.name}
                        value={reseller.settings.parcels.cancelEmail.senderName}
                        onChange={this.onChangeValue.bind(this, 'parcels.cancelEmail.senderName')}
                        onBlur={this.onSaveValue.bind(this, 'parcels.cancelEmail.senderName')}
                    />

                    <Input
                        label='Afzender emailadres'
                        placeholder={(reseller.settings.useOwnSMTP && reseller.settings.smtpUser) || reseller.settings.companyAddress.email || 'noreply@veloyd.nl'}
                        value={reseller.settings.parcels.cancelEmail.replyToEmail}
                        onChange={this.onChangeValue.bind(this, 'parcels.cancelEmail.replyToEmail')}
                        onBlur={this.onSaveValue.bind(this, 'parcels.cancelEmail.replyToEmail')}
                    />

                    <br/>
                    <Editor
                        label='Onderwerp'
                        as='input'
                        tags={['bedrijfsnaam', 'klant', 'naam', 'referentie', 'vervoerder', 'barcode']}
                        value={reseller.settings.parcels.cancelEmail.emailSubject}
                        onChange={this.onSaveValue.bind(this, 'parcels.cancelEmail.emailSubject')}
                    />

                    <Editor
                        label='Bericht'
                        tags={['bedrijfsnaam', 'klant', 'naam', 'referentie', 'vervoerder', 'barcode']}
                        value={reseller.settings.parcels.cancelEmail.emailBody}
                        onChange={this.onSaveValue.bind(this, 'parcels.cancelEmail.emailBody')}
                    />

                    <div style={{position: 'absolute', top: 90, left: 1100, width: 650}}>
                        <EmailPreview
                            type='cancelEmail'
                            html={cancelEmailTemplate({
                                logo: reseller.settings.logo || '/images/logoPlaceholder.png',
                                message: reseller.settings.parcels.cancelEmail.emailBody?.replace(/\n/g, '<br/>') || '',
                                color: reseller.settings.color || Colors.brandColor,
                                parcel: {
                                    customerName: 'Klant',
                                    address: {
                                        name: 'John Doe',
                                        attention: '',
                                        street: 'Kastanjelaan',
                                        nr: '400',
                                        addition: '',
                                        street2: '',
                                        postalCode: '5616 LZ',
                                        city: 'Eindhoven',
                                        country: 'NL'

                                    },
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                }

                            })}
                        />
                    </div>
                </>
                }

            </Panel>
        )
    }
}

export default (CustomerTrackTraceEmail)
