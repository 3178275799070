
import React from 'react'

import {Alert, IconButton, Info, P} from '../UI/index.js'

class UploadImage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    upload(event) {
        this.setState({error: ''})
        event.preventDefault()
        this.fileControl.click()
    }

    saveImage(event) {
        event.preventDefault()
        const {maxWidth, maxHeight, minHeight, minWidth, showDimensionsError} = this.props

        const reader = new FileReader()
        const img = document.createElement('img')

        reader.onloadend = (event) => {
            img.src = event.target.result

            img.onload = () => {
                if (showDimensionsError) {
                    if (!(img.width <= maxWidth && img.width >= minWidth && img.height <= maxHeight && img.height >= minHeight)) {
                        this.setState({error: `
                        Afbeelding is niet het gewenste formaat:
                        Minimale hoogte: ${minHeight} pixels
                        Maximale hoogte: ${maxHeight} pixels
                        Minimale breedte: ${minWidth} pixels
                        Maximale breedte: ${maxWidth} pixels
                        `})
                        return
                    }
                }
                const MAX_WIDTH = maxWidth || 256
                const MAX_HEIGHT = maxHeight || 128

                if (img.width <= MAX_WIDTH && img.height <= MAX_HEIGHT) {
                    this.props.onChange(event.target.result)
                } else {
                    const canvas = document.createElement('canvas')
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0)

                    let width = img.width
                    let height = img.height

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width
                            width = MAX_WIDTH
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height
                            height = MAX_HEIGHT
                        }
                    }
                    canvas.width = width
                    canvas.height = height
                    ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    const dataurl = canvas.toDataURL('image/png')
                    this.props.onChange(dataurl)
                }
            }
        }

        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0])
        }
    }

    removeImage(event) {
        this.setState({error: ''})
        event.preventDefault()
        this.props.onChange('')
    }

    render() {
        const {error} = this.state
        const {style, label, info, infoIcon, image} = this.props

        const labelStyle = {
            display: 'flex',
            alignItems: 'center'
        }

        const defaultStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 'auto',
            minHeight: 42,
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 30
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <>
                <div style={defaultStyle}>
                    {label &&
                    <P style={labelStyle}>
                        <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                        {info && <Info icon={infoIcon} text={info}/>}
                    </P>
                    }

                    <div style={{display: 'flex', alignItems: 'center'}}>

                        {image &&
                        <img
                            id="image" src={image}
                            style={{maxWidth: 128, maxHeight: 64, marginRight: 12}}
                        />
                        }

                        <IconButton onClick={this.upload.bind(this)}>
                            <i className={image ? 'mdi mdi-pencil': 'mdi mdi-upload'}/>
                        </IconButton>

                        {image &&
                        <IconButton onClick={this.removeImage.bind(this)}>
                            <i className='mdi mdi-delete'/>
                        </IconButton>
                        }
                    </div>
                    <input
                        style={{display: 'none'}}
                        type="file" accept="image/png"
                        ref={(ref) => this.fileControl = ref}
                        onChange={this.saveImage.bind(this)}
                    />
                </div>
                {error && <Alert variant='danger'>{error}</Alert>}
            </>
        )
    }
}

export default UploadImage
