import React from 'react'

import {Alert, Button, IconButton, Modal, H4, P, ListItem, ProgressBar, RadioButton, Colors, DatePicker, Input} from '../UI/index.js'

export default class Popup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            loading: false,
            title: '',
            text: '',
            input: null,
            inputValue: '',
            options: [],
            progressNow: 0,
            progressMax: null,
            onConfirm: null,
            onClose: null,
            message: '',
            info: '',
            warning: '',
            error: '',
            errors: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))

        this.onKeyDown = this.onKeyDown.bind(this)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onKeyDown(event) {
        const {noEnter} = this.props
        const {isOpen, options} = this.state

        if (isOpen && !noEnter && event.keyCode === 13 && !options?.length) {
            this.onClickConfirm()
        }
    }

    open(title, text, onConfirm, onClose) {
        this.setState({isOpen: true, title, text, onConfirm, onClose})

        document.addEventListener('keydown', this.onKeyDown)

        if (!onConfirm) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    setInput(input) {
        this.setState({input, inputValue: input.value})
    }


    openOptions(title, text, options, onConfirm) {
        this.setState({isOpen: true, title, text, options, onConfirm})

        if (!onConfirm) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    close() {
        this.setState({isOpen: false}, () => {
            typeof this.state.onClose === 'function' && this.state.onClose()
            this.setState(this.initialState)
        })

        document.removeEventListener('keydown', this.onKeyDown)
    }

    onClickOption(option) {
        this.close()

        if (typeof this.state.onConfirm === 'function') {
            this.state.onConfirm(option)
        } else {
            this.resolve(option)
        }
    }

    onClickConfirm() {
        this.setState({loading: true, message: '', error: ''})

        if (typeof this.state.onConfirm === 'function') {
            this.state.onConfirm()
        } else {
            if (this.state.input) {
                this.resolve(this.state.inputValue)
            } else {
                this.resolve(true)
            }
        }
    }


    setMessage(message) {
        this.setState({message, loading: false})
    }

    setInfo(info) {
        this.setState({info})
    }

    setWarning(warning) {
        this.setState({warning})
    }

    setError(error) {
        this.setState({error, loading: false})
    }


    setErrors(errors) {
        this.setState({errors, loading: false})
    }

    setProgress(progressNow, progressMax) {
        this.setState({progressNow, progressMax})
    }

    render() {
        const {isOpen, title, input, inputValue, text, options, progressNow, progressMax, loading, message, info, warning, error, errors} = this.state
        const {noEsc, closeButton, showOptionRadioButton, confirmBtnText, cancelBtnText, noCancelBtn} = this.props

        return (
            <Modal show={isOpen} noEsc={noEsc} style={{width: errors.length > 0 ? 800 : 400, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>

                    {title &&
                        <H4>{title}</H4>
                    }

                    <div style={{flex: 1}}/>

                    {closeButton &&
                        <IconButton onClick={this.close.bind(this)}>
                            <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                        </IconButton>
                    }
                </div>

                {text &&
                    <P style={{marginBottom: 30, whiteSpace: 'pre-wrap'}}>{text}</P>
                }

                {input?.type === 'date' &&
                    <DatePicker
                        label={input.label}
                        value={inputValue}
                        onChange={(event) => this.setState({inputValue: event.target.value})}
                    />
                }

                {input?.type === 'text' &&
                    <Input
                        label={input.label}
                        value={inputValue}
                        onChange={(event) => this.setState({inputValue: event.target.value})}
                    />
                }

                {!!options.length &&
                    <div style={{maxHeight: 600, overflowY: 'auto'}}>
                        {options.map((option) => {
                            if (typeof option.title === 'string' && typeof option.value === 'string') {
                                return (
                                    <ListItem
                                        key={`${option.value}${option.subvalue || ''}`}
                                        disabled={option.disabled}
                                        onClick={option.disabled ? () => {} : () => this.onClickOption(option)}
                                    >
                                        {showOptionRadioButton &&
                                            <RadioButton
                                                style={{marginBottom: 0}}
                                                disabled={option.disabled}
                                                checked={option.selected}
                                            />
                                        }

                                        <P ellipsis style={{flex: 1}} disabled={option.disabled} >{option.title}</P>

                                        {option.append &&
                                            <div style={{marginLeft: 6}}>{option.append}</div>
                                        }
                                    </ListItem>
                                )
                            } else {
                                return option
                            }
                        })}
                    </div>
                }

                {progressMax &&
                    <ProgressBar now={progressNow} max={progressMax}/>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {info &&
                    <Alert variant='info'>{info}</Alert>
                }

                {warning &&
                    <Alert variant='warning'>{warning}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {!noCancelBtn && !message && !error && !options.length &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            {cancelBtnText || 'Annuleren'}
                        </Button>
                    }

                    {!message && !error && !options.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.onClickConfirm.bind(this)}
                        >
                            {confirmBtnText || 'Bevestigen'}
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

