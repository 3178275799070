import React from 'react'

import _ from 'underscore'

import {CarrierLogo, H3, S2, P, Panel, Info, Row, Colors} from '../../../../components/UI/index.js'
import {Check} from '../../../../components/UI/index.js'

class ParcelProducts extends React.Component {
    onChangeProduct(product, carrier, event) {
        const reseller = {...this.props.reseller}

        reseller.settings.parcels.products = reseller.settings.parcels.products || {}
        reseller.settings.parcels.products[product] = reseller.settings.parcels.products[product] || {}
        reseller.settings.parcels.products[product][carrier.name] = event.target.checked

        this.props.onChange(reseller, 'parcels.products')
    }


    render() {
        const {reseller, carriers} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        let products = []

        enabledCarriers.map((carrier) => {
            _.keys(carrier.products).map((product) => {
                products = _.uniq([...products, product]).sort()
            })
        })

        return (
            <Panel style={{padding: 20, minWidth: 600, width: 'fit-content'}}>
                <H3 style={{marginBottom: 24}}>Verzendopties</H3>

                {enabledCarriers.length < 1 &&
                    <S2 style={{marginBottom: 30}}>Er moet minimaal 1 vervoerders zijn geactiveerd voordat verzendopties kunnen worden ingesteld.</S2>
                }

                <div style={{display: 'flex', position: 'sticky', width: '100%', paddingLeft: 300, top: 0, paddingTop: 24, backgroundColor: Colors.backgroundWhite}}>
                    {enabledCarriers.map((carrier) => {
                        return (
                            <div key={`header${carrier.name}`} style={styles.column}>
                                <CarrierLogo
                                    size={45}
                                    carriers={carriers}
                                    value={carrier.name}
                                />
                                <P style={{marginBottom: 4}}>{carrier.displayName || carrier.name}</P>
                            </div>
                        )
                    })}
                </div>
                <br/>

                {products.map((product, index) => {
                    return (
                        <div key={index}>
                            <div style={{...styles.row}}>
                                <P style={styles.rowHeader}>
                                    <Row>
                                        {product}

                                        {product === 'Retour' &&
                                            <Info text='Activatie van het product Retour maakt ook het gebruik van het retourportaal voor klanten mogelijk'/>
                                        }

                                        {product === 'Adreslabel' &&
                                            <Info text='Een zending aangemeld met optie Adreslabel komt niet in de planning te staan, en krijgt géén track & trace code.'/>
                                        }
                                    </Row>
                                </P>

                                {enabledCarriers.map((carrier, index) => {
                                    let carrierHasProduct = !!carrier.products[product]
                                    const settingsHasProduct = carrierHasProduct && !!reseller.settings.parcels.products[product]

                                    if (carrierHasProduct && (carrier.name === 'PostNL-MSG' || carrier.name === 'DHL' || carrier.name === 'DHL-MSG') && product === 'Aangetekend') {
                                        carrierHasProduct = reseller.settings.parcels.carriers?.[carrier.name].signedEnabled
                                    }

                                    if (carrierHasProduct && (carrier.name === 'PostNL-MSG') && product === 'Aangetekende brief') {
                                        carrierHasProduct = reseller.settings.parcels.carriers?.[carrier.name].signedLetterEnabled
                                    }

                                    return (
                                        <div key={index} style={styles.column}>
                                            <Check
                                                disabled={!carrierHasProduct}
                                                checked={settingsHasProduct && reseller.settings.parcels.products[product][carrier.name]}
                                                onChange={this.onChangeProduct.bind(this, product, carrier)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </Panel>
        )
    }
}

const styles = {
    row: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
        paddingLeft: 6,
        height: 48,
        borderBottom: `1px solid ${Colors.grey20}`
    },
    rowHeader: {
        display: 'flex',
        alignItems: 'center',
        width: 300,
        height: 30
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 90,
        marginRight: 12
    }
}

export default (ParcelProducts)
