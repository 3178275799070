import io from 'socket.io-client'

const options = {
    transports: ['websocket']
}

const socket = io(options)

connect()

socket.on('joined', () => {
    console.log('connected')
})

socket.on('disconnect', () => {
    console.log('disconnected')
    connect()
})

function connect() {
    if (sessionStorage.token) {
        console.log('connecting...')
        socket.emit('join', sessionStorage.token, (err) => {
            if (err && err.name === 'TokenExpiredError') {
                sessionStorage.removeItem('token')
                window.location = '/login'
            }
        })
    }
}

export default socket
