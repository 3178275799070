import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import OrderActions from '../actions/OrderActions.js'
import filter from '../utils/filter.js'
import dbCodes from '../../server/dbCodes.js'

export default class OrderStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            view: localStorage?.getItem('dashboard-view') || 'routes',
            orderStartDate: moment().format('YYYY-MM-DD'),
            orderEndDate: moment().format('YYYY-MM-DD'),
            startTime: '00:00',
            endTime: '24:00',
            startInclude: true,
            endInclude: true,
            orderQueries: [],

            orders: {},
            groupedByRouteId: {},
            filteredOrders: {},
            selectedOrders: [],
            ordersLoading: true,

            routes: {},
            activeRouteTab: 'open',
            selectedRouteId: '',
            someRouteHidden: false,

            mapOptions: localStorage.mapOptions ? JSON.parse(localStorage.mapOptions): {
                showRoutePanel: localStorage.showRoutePanel ? localStorage.showRoutePanel === 'true' : true,
                showOrderPanel: localStorage.showOrderPanel ? localStorage.showOrderPanel === 'true' : true,
                showOrdersOnMap: localStorage.showOrdersOnMap ? localStorage.showOrdersOnMap === 'true' : true,
                showCompletedAddressesOnMap: localStorage.showCompletedAddressesOnMap ? localStorage.showCompletedAddressesOnMap === 'true' : false,
                showRoutesOnMap: localStorage.showRoutesOnMap ? localStorage.showRoutesOnMap === 'true' : true,
                showStopNumbersOnMap: localStorage.showStopNumbersOnMap ? localStorage.showStopNumbersOnMap === 'true' : true,
                showExpectedOnMap: localStorage.showExpectedOnMap ? localStorage.showExpectedOnMap === 'true' : true
            },

            showRoutePanel: localStorage.showRoutePanel ? localStorage.showRoutePanel === 'true' : true,
            showOrderPanel: localStorage.showOrderPanel ? localStorage.showOrderPanel === 'true' : true,
            showOrdersOnMap: localStorage.showOrdersOnMap ? localStorage.showOrdersOnMap === 'true' : true,
            showCompletedAddressesOnMap: localStorage.showCompletedAddressesOnMap ? localStorage.showCompletedAddressesOnMap === 'true' : false,
            showRoutesOnMap: localStorage.showRoutesOnMap ? localStorage.showRoutesOnMap === 'true' : true,
            showStopNumbersOnMap: localStorage.showStopNumbersOnMap ? localStorage.showStopNumbersOnMap === 'true' : true,

            orderLimitWarning: false,
            centerViewOnSelected: false
        }

        this.updateOrderIds = []
        this.updateRouteIds = []
        this.updateTimeout = null

        this.listenables = OrderActions

        OrderActions.get(true, true)

        socket.on('updateOrders', () => {
            OrderActions.get()
        })

        socket.on('updateOrder', this.updateOrders.bind(this))
    }

    updateOrders(orderIds, routeIds, forceUpdate) {
        orderIds = !orderIds ? [] : typeof orderIds === 'string' ? [orderIds] : orderIds
        routeIds = !routeIds ? [] : typeof routeIds === 'string' ? [routeIds] : routeIds

        if (typeof routeIds === 'boolean') {
            forceUpdate = routeIds
            routeIds = []
        }

        console.log(`updateOrders force: ${forceUpdate}`)

        if (forceUpdate) {
            this.processUpdateOrders(orderIds, routeIds)
        } else {
            if (this.updateTimeout) {
                this.updateOrderIds = _.uniq([...this.updateOrderIds, ...orderIds])
                this.updateRouteIds = _.uniq([...this.updateRouteIds, ...routeIds])
            } else {
                this.processUpdateOrders(_.uniq([...this.updateOrderIds, ...orderIds]), _.uniq([...this.updateRouteIds, ...routeIds]))
                this.updateOrderIds = []
                this.updateRouteIds = []

                this.updateTimeout = setTimeout(() => {
                    this.updateTimeout = null

                    if (this.updateOrderIds.length || this.updateRouteIds.length) {
                        this.processUpdateOrders([...this.updateOrderIds], [...this.updateRouteIds])
                        this.updateOrderIds = []
                        this.updateRouteIds = []
                    }
                }, 5000)
            }
        }
    }

    processUpdateOrders(orderIds, routeIds) {
        console.log(`processUpdateOrders orderIds: ${orderIds.length} routeIds: ${routeIds.length}`)

        const {orderStartDate, orderEndDate} = this.state

        socket.emit('orders.get', sessionStorage.token, orderStartDate, orderEndDate, orderIds, routeIds, (err, updatedOrders=[], updatedRoutes) => {
            if (err) {
                console.log(err)
                return
            }

            // TODO rename orders to orderObject everywhere?
            const orderObject = {}
            updatedOrders.map((order) => {
                orderObject[order._id] = order
            })
            updatedOrders = orderObject

            // TODO rename routes to routeObject everywhere?
            const routeObject = {}
            updatedRoutes.map((route) => {
                routeObject[route._id] = route
            })
            updatedRoutes = routeObject


            const routes = {...this.state.routes}
            const groupedByRouteId = {...this.state.groupedByRouteId}
            let routesHasChanged = false

            // update routes
            routeIds.map((routeId) => {
                const updatedRoute = updatedRoutes[routeId]

                if (updatedRoute) {
                    routes[routeId] = {...routes[routeId], ...updatedRoute}
                    routesHasChanged = true
                } else {
                    if (routes[routeId]) {
                        delete routes[routeId]
                        routesHasChanged = true
                    }
                }
            })

            const {orderQueries, startTime, endTime, startInclude, endInclude} = this.state
            const orders = {...this.state.orders}
            const selectedOrders = [...this.state.selectedOrders]

            let orderHasChanged = false
            const updatedRouteIds = {}

            // update orders
            orderIds.map((id) => {
                const oldOrder = orders[id]
                const order = updatedOrders[id]

                const oldRouteId = oldOrder?.routeId || ''
                const newRouteId = order?.routeId || ''


                if (order) {
                    if (oldRouteId) {
                        if (oldRouteId !== newRouteId) {
                            delete groupedByRouteId[oldRouteId][id]
                        }
                        updatedRouteIds[oldRouteId] = true
                    }


                    if (newRouteId) {
                        if (oldRouteId !== newRouteId) {
                            groupedByRouteId[newRouteId] = groupedByRouteId[newRouteId] || {}
                        }

                        groupedByRouteId[newRouteId][id] = order
                        updatedRouteIds[newRouteId] = true
                    }

                    orders[id] = order
                    orderHasChanged = true
                } else if (oldOrder) {
                    const selectedIndex = selectedOrders.indexOf(id)
                    selectedOrders.splice(selectedIndex, 1)

                    delete orders[id]

                    if (oldRouteId) {
                        delete groupedByRouteId[oldRouteId][id]
                        updatedRouteIds[oldRouteId] = true
                    }

                    orderHasChanged = true
                }
            })

            if (orderHasChanged) {
                // TODO remove duplicate code here and in onGet
                Object.keys(updatedRouteIds).map((routeId) => {
                    if (!routes[routeId]) {
                        return
                    }

                    const route = {...routes[routeId]}

                    route.addresses = []
                    route.colli = []
                    route.reversible = true

                    route.success = 0
                    route.notCompleted = 0
                    route.tooLate = 0

                    // TODO decide wether to do this here or in the orders.js
                    if (route.completedAddresses.length > 0) {
                        route.completedAddresses.map((address, index) => {
                            // Add index to id to prevent duplicate keys. This can happen when an address is completed and then added to the route again and causes problems in sortable lists
                            address.id = `${address.orderId}_${address.index}_${index}  `

                            if (address.success) {
                                route.success += 1
                            } else {
                                route.notCompleted += 1
                            }

                            if (address.timeOfArrival > address.endTime) {
                                route.tooLate += 1
                            }
                        })
                    }

                    Object.values(groupedByRouteId[routeId] || {}).map((order) => {
                        const remainingAddresses = order.addresses.filter((address) => !address.hasArrived)

                        if (remainingAddresses.length > 1 && order.addressOrder !== 'random') {
                            route.reversible = false
                        }

                        order.addresses.map((address) => {
                            if (address.hasArrived) {
                                return
                            }

                            if (order.status === dbCodes.status.verwacht()) {
                                address.isExpected = true
                            }

                            route.addresses.push({
                                id: `${order._id}_${address.index}`,
                                orderId: order._id,
                                ...address,
                                ...order.addresses[address.index],
                                senderAddress: order.senderAddress,
                                customerAddress: order.customerAddress
                            })
                        })

                        order.colli.map((collo) => {
                            if (['expected', 'pickup', 'outforpickup', 'athub', 'outfordelivery'].includes(collo.status)) {
                                route.colli.push(collo)
                            }
                        })
                    })

                    route.addresses = _.sortBy(route.addresses, (address) => address.priority)

                    routes[routeId] = route
                })

                const filteredOrders = filter.orders(orders, routes, orderQueries, startTime, endTime, startInclude, endInclude)
                console.log('OrderStore.processUpdateOrders: orderHasChanged')
                this.setState({orders, routes, groupedByRouteId, filteredOrders, selectedOrders})
            } else if (routesHasChanged) {
                console.log('OrderStore.processUpdateOrders: routesHasChanged)')
                this.setState({routes})
            }
        })
    }


    onSetView(view, centerViewOnSelected) {
        this.setState({view, centerViewOnSelected})
        setTimeout(() => {
            this.setState({centerViewOnSelected: false})
        }, 1000)
    }

    onSetDates(orderStartDate, orderEndDate) {
        this.setState({orderStartDate, orderEndDate, selectedOrders: [], selectedRouteId: '', focusedRouteId: '', someRouteHidden: false})
        OrderActions.get(true, true)
    }

    onSetTimes(startTime, endTime, startInclude, endInclude) {
        const {orders, routes, orderQueries} = this.state
        const filteredOrders = filter.orders(orders, routes, orderQueries, startTime, endTime, startInclude, endInclude)


        this.setState({filteredOrders, selectedOrders: [], startTime, endTime, startInclude, endInclude})
    }

    onSetQueries(orderQueries) {
        const {orders, routes, startTime, endTime, startInclude, endInclude} = this.state

        const filteredOrders = filter.orders(orders, routes, orderQueries, startTime, endTime, startInclude, endInclude)
        this.setState({filteredOrders, selectedOrders: [], orderQueries})
    }

    onSetSelected(selectedOrders) {
        this.setState({selectedOrders})
    }


    onGet(loading) {
        const {orderStartDate, orderEndDate} = this.state
        this.setState({ordersLoading: !!loading})

        socket.emit('orders.get', sessionStorage.token, orderStartDate, orderEndDate, null, null, (err, orders, routes) => {
            if (err) {
                alert(err.message)
                return
            }

            // TODO rename orders to orderObject everywhere?
            const orderObject = {}
            orders.map((order) => {
                orderObject[order._id] = order
            })
            orders = orderObject

            // TODO rename routes to routeObject everywhere?
            const routeObject = {}
            routes.map((route) => {
                routeObject[route._id] = route
            })
            routes = routeObject

            const {selectedOrders, orderQueries, startTime, endTime, startInclude, endInclude} = this.state


            const filteredOrders = filter.orders(orders, routes, orderQueries, startTime, endTime, startInclude, endInclude)

            const groupedByRouteId = {}

            Object.values(orders).map((order) => {
                if (order.routeId) {
                    groupedByRouteId[order.routeId] = groupedByRouteId[order.routeId] || {}
                    groupedByRouteId[order.routeId][order._id] = order
                }
            })

            Object.values(routes).map((route) => {
                route.addresses = []
                route.colli = []
                route.reversible = true

                route.success = 0
                route.notCompleted = 0
                route.tooLate = 0

                // TODO decide wether to do this here or in the orders.js
                if (route.completedAddresses.length > 0) {
                    route.completedAddresses.map((address, index) => {
                        // Add index to id to prevent duplicate keys. This can happen when an address is completed and then added to the route again and causes problems in sortable lists
                        address.id = `${address.orderId}_${address.index}_${index}  `

                        if (address.success) {
                            route.success += 1
                        } else {
                            route.notCompleted += 1
                        }

                        if (address.timeOfArrival > address.endTime) {
                            route.tooLate += 1
                        }
                    })
                }

                Object.values(groupedByRouteId[route._id] || {}).map((order) => {
                    const remainingAddresses = order.addresses.filter((address) => !address.hasArrived)

                    if (remainingAddresses.length > 1 && order.addressOrder !== 'random') {
                        route.reversible = false
                    }

                    order.addresses.map((address) => {
                        if (address.hasArrived) {
                            return
                        }

                        if (order.status === dbCodes.status.verwacht()) {
                            address.isExpected = true
                        }

                        route.addresses.push({
                            id: `${order._id}_${address.index}`,
                            orderId: order._id,
                            ...address,
                            ...order.addresses[address.index],
                            senderAddress: order.senderAddress,
                            customerAddress: order.customerAddress
                        })
                    })

                    order.colli.map((collo) => {
                        if (['expected', 'pickup', 'outforpickup', 'athub', 'outfordelivery'].includes(collo.status)) {
                            route.colli.push(collo)
                        }
                    })
                })

                route.addresses = _.sortBy(route.addresses, (address) => address.priority)
            })

            this.setState({
                orders,
                routes,
                groupedByRouteId,
                filteredOrders,
                selectedOrders: selectedOrders.filter((id) => !!filteredOrders[id]),
                ordersLoading: false,
                orderLimitWarning: Object.keys(orders).length >= 35000
            })
        })
    }

    onGetOne(id, callback) {
        socket.emit('orders.getOne', sessionStorage.token, id, callback)
    }

    onGetOrderShifts(date, callback) {
        socket.emit('shifts.getOrderShifts', sessionStorage.token, date, callback)
    }

    onGetRecentCustomerOrders(customerId, nrOfOrders, skipNrOfOrders, callback) {
        socket.emit('orders.getRecentCustomerOrders', sessionStorage.token, customerId, nrOfOrders, skipNrOfOrders, callback)
    }

    onCreate(...args) {
        socket.emit('orders.create', sessionStorage.token, ...args)
    }

    onEdit(...args) {
        socket.emit('orders.edit', sessionStorage.token, ...args)
    }

    onGetTemplate(...args) {
        socket.emit('orders.getTemplate', sessionStorage.token, ...args)
    }

    onEditTemplate(...args) {
        socket.emit('orders.editTemplate', sessionStorage.token, ...args)
    }

    onEditInfo(...args) {
        socket.emit('orders.editInfo', sessionStorage.token, ...args)
    }

    onEditDate(...args) {
        socket.emit('orders.editDate', sessionStorage.token, ...args)
    }

    onEditAddress(...args) {
        socket.emit('orders.editAddress', sessionStorage.token, ...args)
    }

    onEditCollo(...args) {
        socket.emit('orders.editCollo', sessionStorage.token, ...args)
    }

    onEditAdministration(...args) {
        socket.emit('orders.editAdministration', sessionStorage.token, ...args)
    }

    onEditMoreOptions(...args) {
        socket.emit('orders.editMoreOptions', sessionStorage.token, ...args)
    }

    onAtHub(...args) {
        socket.emit('orders.atHub', sessionStorage.token, ...args)
    }

    onReschedule(...args) {
        socket.emit('orders.reschedule', sessionStorage.token, ...args)
    }

    onOnHold(...args) {
        socket.emit('orders.onHold', sessionStorage.token, ...args)
    }

    onCancel(...args) {
        socket.emit('orders.cancel', sessionStorage.token, ...args)
    }

    onNotSupplied(...args) {
        socket.emit('orders.notSupplied', sessionStorage.token, ...args)
    }

    onManco(...args) {
        socket.emit('orders.manco', sessionStorage.token, ...args)
    }

    onComplete(...args) {
        socket.emit('orders.complete', sessionStorage.token, ...args)
    }

    onPickup(...args) {
        socket.emit('orders.pickup', sessionStorage.token, ...args)
    }

    onDeliver(...args) {
        socket.emit('orders.deliver', sessionStorage.token, ...args)
    }

    onGetNotPossibleReasons(...args) {
        socket.emit('orders.getNotPossibleReasons', sessionStorage.token, ...args)
    }


    onRemove(id, callback) {
        socket.emit('orders.remove', sessionStorage.token, id, callback)
    }

    onRemoveTemplate(order, callback) {
        socket.emit('orders.removeTemplate', sessionStorage.token, order, callback)
    }

    onPrintLabels(ids, nrOfEmptyLabels, adressIndex, colloIndex, callback) {
        const printerPageSize = localStorage.printerPageSize || 'A6'
        socket.emit('orders.printLabels', sessionStorage.token, ids, printerPageSize, nrOfEmptyLabels, adressIndex, colloIndex, callback)
    }

    onCalculatePrice(id, pricingOption, newPrice, callback) {
        socket.emit('orders.calculatePrice', sessionStorage.token, id, pricingOption, newPrice, callback)
    }

    onGetPrice(order, callback) {
        socket.emit('orders.getPrice', sessionStorage.token, order, callback)
    }

    onAddHistoryComment(...args) {
        socket.emit('orders.addHistoryComment', sessionStorage.token, ...args)
    }

    onSendRetour(...args) {
        socket.emit('orders.sendRetour', sessionStorage.token, ...args)
    }

    onCancelRetour(...args) {
        socket.emit('orders.cancelRetour', sessionStorage.token, ...args)
    }


    onNewRoute(...args) {
        socket.emit('orders.newRoute', sessionStorage.token, ...args)
    }

    onUpdateRoute(routeId, update, callback) {
        const routes = {...this.state.routes}

        routes[routeId] = {...routes[routeId], ...update, loading: true}

        this.setState({routes})

        socket.emit('orders.updateRoute', sessionStorage.token, routeId, update, (err) => {
            const routes = {...this.state.routes}

            routes[routeId] = {...routes[routeId], loading: false}

            this.setState({routes, error: err})

            typeof callback === 'function' && callback(err)
        })
    }

    onRescheduleRoute(routeId, date, callback) {
        const routes = {...this.state.routes}

        routes[routeId] = {...routes[routeId], loading: true}

        this.setState({routes})

        socket.emit('orders.rescheduleRoute', sessionStorage.token, routeId, date, (err, errors) => {
            const routes = {...this.state.routes}

            routes[routeId] = {...routes[routeId], loading: false}

            this.setState({routes, error: err})

            typeof callback === 'function' && callback(err, errors)
        })
    }

    onRemoveRoute(...args) {
        socket.emit('orders.removeRoute', sessionStorage.token, ...args)
    }

    onAddToRoute(...args) {
        socket.emit('orders.addToRoute', sessionStorage.token, ...args)
    }


    onAutoSetMessengers(ids, options, callback) {
        const {orderStartDate} = this.state
        options.date = orderStartDate
        socket.emit('orders.autoSetMessengers', sessionStorage.token, ids, options, callback)
    }

    onPlanRoute(routeId, optimize, reverse, changedAddresses, callback) {
        const routes = {...this.state.routes}

        routes[routeId] = {...routes[routeId], loading: true}

        if (changedAddresses) {
            // Completed addressesn are included in changedAddresses because they are rendered in the same list, so we need to filter them out
            changedAddresses = changedAddresses.filter((address) => !address.hasArrived)
            routes[routeId] = {...routes[routeId], addresses: changedAddresses}
        }

        this.setState({routes})

        socket.emit('orders.planRoute', sessionStorage.token, routeId, optimize, reverse, changedAddresses, (err) => {
            const routes = {...this.state.routes}

            routes[routeId] = {...routes[routeId], loading: false}

            this.setState({routes, error: err})

            typeof callback === 'function' && callback(err)
        })
    }

    onSetRouteReady(routeId, ready, callback) {
        const routes = {...this.state.routes}

        routes[routeId] = {...routes[routeId], loading: true}

        this.setState({routes})

        socket.emit('orders.setRouteReady', sessionStorage.token, routeId, ready, (err) => {
            const routes = {...this.state.routes}

            routes[routeId] = {...routes[routeId], loading: false}

            this.setState({routes, error: err})

            typeof callback === 'function' && callback(err)
        })
    }

    onSetRouteLocked(routeId, locked, callback) {
        const routes = {...this.state.routes}

        routes[routeId] = {...routes[routeId], loading: true}

        this.setState({routes})

        socket.emit('orders.setRouteLocked', sessionStorage.token, routeId, locked, (err) => {
            const routes = {...this.state.routes}

            routes[routeId] = {...routes[routeId], loading: false}

            this.setState({routes, error: err})

            typeof callback === 'function' && callback(err)
        })
    }

    onSetAddressLocked(...args) {
        socket.emit('orders.setAddressLocked', sessionStorage.token, ...args)
    }

    onResetRoutesHidden() {
        const routes = {...this.state.routes}

        Object.keys(routes).map((routeId) => {
            if (routes[routeId].hidden) {
                routes[routeId] = {...routes[routeId], hidden: false}
            }
        })

        this.setState({routes, focusedRouteId: '', someRouteHidden: false})
    }

    onSetRouteHidden(routeId) {
        const routes = {...this.state.routes}
        routes[routeId] = {...routes[routeId], hidden: !routes[routeId].hidden}

        const someRouteHidden = _.some(Object.values(routes), (route) => route.hidden)

        this.setState({routes, focusedRouteId: '', someRouteHidden})
    }

    onSetRouteFocus(routeId) {
        const routes = {...this.state.routes}

        if (routeId === this.state.focusedRouteId) {
            routeId = ''
        }

        if (routeId) {
            routes[routeId] = {...routes[routeId], hidden: false}
        }

        const someRouteHidden = _.some(Object.values(routes), (route) => route.hidden)

        this.setState({routes, focusedRouteId: routeId, someRouteHidden})
    }

    onSetActiveRouteTab(activeRouteTab) {
        this.setState({activeRouteTab, selectedRouteId: ''})
    }

    onSetSelectedRoute(selectedRouteId) {
        this.setState({selectedRouteId})
    }

    onChangeTimeslot(...args) {
        socket.emit('orders.changeTimeslot', sessionStorage.token, ...args)
    }

    onCheck(...args) {
        socket.emit('orders.check', sessionStorage.token, ...args)
    }

    onSendDeliveryMail(...args) {
        socket.emit('orders.sendDeliveryMail', sessionStorage.token, ...args)
    }

    onFindByBarcode(...args) {
        socket.emit('orders.findByBarcode', sessionStorage.token, ...args)
    }

    onScan(...args) {
        socket.emit('orders.scan', sessionStorage.token, ...args)
    }


    onSetMapOptions(update) {
        const {mapOptions} = this.state

        this.setState({mapOptions: {...mapOptions, ...update}})
        localStorage.mapOptions = JSON.stringify({...mapOptions, ...update})
    }

    onSetShowRoutePanel() {
        const {showRoutePanel} = this.state
        this.setState({showRoutePanel: !showRoutePanel})
        localStorage.showRoutePanel = this.state.showRoutePanel ? 'true' : 'false'
    }

    onSetShowOrderPanel() {
        const {showOrderPanel} = this.state
        this.setState({showOrderPanel: !showOrderPanel})
        localStorage.showOrderPanel = this.state.showOrderPanel ? 'true' : 'false'
    }

    onSetShowOrdersOnMap() {
        const {showOrdersOnMap} = this.state
        this.setState({showOrdersOnMap: !showOrdersOnMap})
        localStorage.showOrdersOnMap = this.state.showOrdersOnMap ? 'true' : 'false'
    }

    onSetShowRoutesOnMap() {
        const {showRoutesOnMap} = this.state
        this.setState({showRoutesOnMap: !showRoutesOnMap})
        localStorage.showRoutesOnMap = this.state.showRoutesOnMap ? 'true' : 'false'
    }


    onSetShowCompletedAddresses() {
        const {showCompletedAddressesOnMap} = this.state
        this.setState({showCompletedAddressesOnMap: !showCompletedAddressesOnMap})
        localStorage.showCompletedAddressesOnMap = this.state.showCompletedAddressesOnMap ? 'true' : 'false'
    }

    onSetShowStopNumbersOnMap() {
        const {showStopNumbersOnMap} = this.state
        this.setState({showStopNumbersOnMap: !showStopNumbersOnMap})
        localStorage.showStopNumbersOnMap = this.state.showStopNumbersOnMap ? 'true' : 'false'
    }

    onClearError() {
        this.setState({error: ''})
    }

    onGetFile(...args) {
        socket.emit('orders.getFile', sessionStorage.token, ...args)
    }
}
