import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import OrderActions from '../../actions/OrderActions.js'
import {withVehiclesHook} from '../../stores/VehicleStore.js'

import {ChooseRoutePoint, ColorPicker, Colors, DatePicker, FlexColumn, FlexRow, H6, Input, Select, TextArea, TimePicker, Toggle} from '../UI/index.js'
import {Alert, Button, Modal} from '../UI/index.js'
import NewRoute from './NewRoute.js'

class EditRouteModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            route: NewRoute(),
            validation: {},
            loading: false,
            error: '',
            mailLoading: false,
            mailMessage: '',
            mailError: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(route) {
        const {reseller} = this.props

        route = route ? structuredClone(route) : NewRoute(reseller)

        this.setState({modalIsOpen: true, route})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(path, parser, event) {
        const route = {...this.state.route}

        if (!event && parser) {
            event = parser
            parser = null
        }


        if (parser) {
            _.set(route, path, parser(event.target.value))
        } else {
            _.set(route, path, event.target.value)
        }

        if (path === 'vehicleTypeId') {
            const {reseller} = this.props

            const vehicleType = reseller.settings.vehicleTypes.find((vT) => vT._id === event.target.value)

            if (!vehicleType) {
                return
            }

            route.vehicleId = event.target.subvalue || ''

            if (vehicleType) {
                route.stopTime = vehicleType.stopTime
            }

            if (vehicleType.speedDifferenceEnabled) {
                route.customSpeed = true
                const speedDifference = vehicleType.speedDifferenceMode === 'faster' ? -parseInt(vehicleType.speedDifference) : parseInt(vehicleType.speedDifference)
                route.speedDifference = speedDifference
            }
        }

        this.setState({route})
    }

    onChangeChecked(path, event) {
        const route = {...this.state.route}

        _.set(route, path, event.target.checked)

        this.setState({route})
    }

    onSubmit(event) {
        event.preventDefault()

        const {route} = this.state

        this.setState({loading: true, error: ''})

        if (route._id) {
            OrderActions.updateRoute(route._id, route, this.response.bind(this))
        } else {
            OrderActions.newRoute(route, this.response.bind(this))
        }
    }

    response(err) {
        if (err) {
            this.setState({error: err, loading: false})
        } else {
            this.close()
        }
    }

    render() {
        const {modalIsOpen, route, loading, error} = this.state
        const {reseller, users, vehicles} = this.props

        return (
            <Modal
                show={modalIsOpen}
                style={{width: 600}}
                title={route._id ? 'Bewerk route' : 'Nieuwe route'}
                onClose={this.close.bind(this)} closeButton
            >

                <FlexRow>
                    <FlexColumn>
                        <FlexRow>
                            <ColorPicker
                                value={route.color}
                                colors={Colors.routeColors}
                                onChange={this.onChangeValue.bind(this, 'color')}
                            />

                            <Input
                                label='Route'
                                value={route.name}
                                onChange={this.onChangeValue.bind(this, 'name')}
                            />
                        </FlexRow>
                    </FlexColumn>


                    {!route?._id &&
                        <FlexColumn>
                            <DatePicker
                                label='Datum'
                                value={route.date}
                                onChange={this.onChangeValue.bind(this, 'date')}
                            />
                        </FlexColumn>
                    }
                </FlexRow>

                <FlexRow>
                    <FlexColumn>
                        <Select
                            label='Koerier'
                            allowEmptyValue
                            options={Object.values(users).map((user) => {
                                return {value: user._id, title: user.name}
                            })}
                            value={route.messengerId}
                            onChange={this.onChangeValue.bind(this, 'messengerId')}
                        />
                    </FlexColumn>

                    <FlexColumn>
                        <TimePicker
                            label='Starttijd'
                            value={route.plannedStartTime}
                            readOnly={route.started || route.completed}
                            onChange={this.onChangeValue.bind(this, 'plannedStartTime')}
                        />
                    </FlexColumn>
                </FlexRow>

                <ChooseRoutePoint
                    label='Start:'
                    dropdownLabel='Startpunt'
                    address={route.startPoint}
                    route={route}
                    users={users}
                    routeId={route._id}
                    reseller={reseller}
                    readOnly={route.started || route.completed}
                    onChange={(startPoint) => this.setState({route: {...route, startPoint}})}
                />

                <ChooseRoutePoint
                    label='Eind:'
                    dropdownLabel='Eindpunt'
                    address={route.endPoint}
                    route={route}
                    users={users}
                    routeId={route._id}
                    reseller={reseller}
                    readOnly={route.completed}
                    onChange={(endPoint) => this.setState({route: {...route, endPoint}})}
                />

                <H6 style={{marginTop: 24}}>Voertuig</H6>

                <FlexRow>
                    <FlexColumn>
                        <Select
                            label='Voertuig'
                            options={[
                                ...reseller.settings.vehicleTypes.filter((vT) => !vT.isArchived).map((vehicleType) => ({
                                    value: vehicleType._id,
                                    title: vehicleType.name,
                                    icon: `mdi mdi-${vehicleType.icon}`
                                })),
                                ...vehicles.filter((v) => !v.isArchived).sort((a, b) => {
                                    const unavailable = b.notAvailableDateTimeRanges?.find((range) => moment(route.date).isBetween(range.start, range.end, 'day', '[]'))

                                    return unavailable ? -9999 : (a.order - b.order)
                                }).map((vehicle) => {
                                    const activeVehicleType = reseller.settings.vehicleTypes.find((type) => type._id === vehicle.typeId)
                                    return {
                                        value: activeVehicleType?._id || vehicle._id,
                                        ...(activeVehicleType && {subvalue: vehicle._id}),
                                        title: vehicle.name,
                                        icon: `mdi mdi-${activeVehicleType ? activeVehicleType.icon : vehicle.icon}`,
                                        disabled: vehicle.notAvailableDateTimeRanges?.find((range) => moment(route.date).isBetween(range.start, range.end, 'day', '[]'))
                                    }
                                })
                            ]}
                            value={route.vehicleTypeId || reseller.settings.defaultVehicle}
                            subvalue={route.vehicleId}
                            readOnly={route.completed}
                            onChange={this.onChangeValue.bind(this, 'vehicleTypeId')}
                            groupSubvalues
                        />
                    </FlexColumn>

                    <FlexColumn>
                        <Input
                            type='number'
                            label='Stoptijd (seconden)'
                            value={route.stopTime}
                            readOnly={route.completed}
                            onChange={this.onChangeValue.bind(this, 'stopTime', parseInt)}
                        />
                    </FlexColumn>
                </FlexRow>

                {false &&
                <FlexRow style={{marginTop: 12}}>
                    <FlexColumn>
                        <Toggle
                            label='Snelheid handmatig instellen'
                            checked={route.customSpeed}
                            disabled={route.completed}
                            onChange={this.onChangeChecked.bind(this, 'customSpeed')}
                        />
                    </FlexColumn>

                    <FlexColumn>
                        {route.customSpeed &&
                            <Input
                                type='number'
                                label='Afwijking snelheid'
                                value={route.speedDifference}
                                readOnly={route.completed}
                                onChange={this.onChangeValue.bind(this, 'speedDifference', parseInt)}
                                append='%'
                            />
                        }
                    </FlexColumn>
                </FlexRow>
                }


                <H6 style={{marginTop: 24}}>Overige</H6>

                <FlexRow>
                    <TextArea
                        label='Instructie'
                        value={route.instructions}
                        onChange={this.onChangeValue.bind(this, 'instructions')}
                    />
                </FlexRow>

                <FlexRow style={{marginTop: 12}}>
                    <FlexColumn>
                        <Toggle
                            label='Optimaliseren voor tijdvakken'
                            checked={route.optimizeForTimeslots}
                            disabled={route.completed}
                            onChange={this.onChangeChecked.bind(this, 'optimizeForTimeslots')}
                        />
                    </FlexColumn>
                    <FlexColumn/>
                </FlexRow>

                <FlexRow>
                    <FlexColumn>
                        <Toggle
                            label='Route aanpasbaar door koerier'
                            checked={route.adjustableByMessenger}
                            disabled={route.completed}
                            onChange={this.onChangeChecked.bind(this, 'adjustableByMessenger')}
                        />
                    </FlexColumn>
                    <FlexColumn/>
                </FlexRow>


                {error && <Alert variant="danger">{error}</Alert>}

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button loading={loading} onClick={this.onSubmit.bind(this)}>
                        Opslaan
                    </Button>
                </div>
            </Modal>
        )
    }
}

export default withVehiclesHook(EditRouteModal)
