import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import dbCodes from '../../../server/dbCodes.js'
import {Alert, Card, Colors, Button, IconButton, Modal, H4, Popup, Link, SelectRoute, S1, S2, P, ScrollView, Row, Column, DropdownActionButton, Input, Spinner, FlexColumn} from '../UI/index.js'
import NotSuppliedOrderPopup from './NotSuppliedOrderPopup.js'
import MancoOrderPopup from './MancoOrderPopup.js'
import CancelOrderPopup from './CancelOrderPopup.js'
import AtHubOrderPopup from './AtHubOrderPopup.js'
import RescheduleOrderPopup from './RescheduleOrderPopup.js'
import OnHoldOrderPopup from './OnHoldOrderPopup.js'
import ReturnOrderPopup from './ReturnOrderPopup.js'
import PickupDeliverPopup from './PickupDeliverPopup.js'
import Info from './Info.js'
import Address from './Address.js'
import Collo from './Collo.js'
import Temperature from './Temperature.js'
import SendEmailNotificationPopup from './SendEmailNotificationPopup.js'
import Administration from './Administration.js'
import MoreOptions from './MoreOptions.js'
import moment from 'moment'
import calcDistance from '../../../utils/calcDistance.js'


class ViewOrderModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            orderLoading: false,
            orderError: null,
            order: null,
            removeAll: false,
            entryPopup: null,
            entryPopupView: '',
            loading: false,
            historyComment: '',
            message: '',
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(id, fromScan) {
        if (typeof id === 'object') {
            const order = id

            console.log(order)

            this.setState({modalIsOpen: true, order, email: order.email})
        } else {
            this.setState({modalIsOpen: true, orderLoading: true})

            OrderActions.getOne(id, (err, order) => {
                if (err) {
                    this.setState({orderLoading: false, orderError: err})
                } else {
                    console.log(order)
                    this.setState({orderLoading: false, orderError: null, order, email: order.email})
                }
            })
        }
        this.setState({fromScan})
    }

    close() {
        const {fromScan} = this.state
        const {match: {params: orderId = ''}, history} = this.props

        typeof fromScan === 'function' && fromScan()

        this.setState(this.initialState)

        if (orderId) {
            history.push('/planning')
        }
    }

    onClickCancelRetour(id) {
        this.popup.open('Annuleer retour', 'De retourrit wordt verwijderd en de voorgaande rit wordt weer actief.', () => {
            OrderActions.cancelRetour(id, '', (err, order) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.close()

                    if (order) {
                        this.setState({order})
                    } else {
                        this.close()
                    }
                }
            })
        })
    }

    onClickViewImage(data, event) {
        event.preventDefault()
        const w = window.open('about:blank')
        const image = new Image()
        image.src = data
        setTimeout(() => {
            w.document.write(image.outerHTML)
        }, 0)
    }

    onClickLocation(location, event) {
        event.preventDefault()
        window.open(`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`)
    }

    onClickOpenImage(entry) {
        if (entry.imageId) {
            OrderActions.getFile(entry.imageId, (err, file) => {
                entry.image = `data:image/png;base64,${file}`

                this.setState({entryPopup: entry, entryPopupView: 'image'})
            })
        } else {
            this.setState({entryPopup: entry, entryPopupView: 'image'})
        }
    }

    onClickOpenSignature(entry) {
        if (entry.signatureId) {
            OrderActions.getFile(entry.signatureId, (err, file) => {
                entry.signature = `data:image/png;base64,${file}`

                this.setState({entryPopup: entry, entryPopupView: 'signature'})
            })
        } else {
            this.setState({entryPopup: entry, entryPopupView: 'signature'})
        }
    }

    onAddHistoryComment() {
        const {historyComment, order} = this.state
        this.setState({loading: true})

        OrderActions.addHistoryComment(order._id, historyComment, (err, updatedOrder) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({order: updatedOrder})
            }
            this.setState({loading: false, historyComment: ''})
        })
    }

    response(err, response) {
        if (err) {
            this.setState({loading: false, message: '', error: err})
        } else {
            this.setState({loading: false, message: response, error: ''})
        }
    }

    render() {
        const {modalIsOpen, orderLoading, orderError, order, entryPopup, entryPopupView, historyComment, message, error} = this.state
        const {history, reseller, usersObject, routes, customers, editOrder, onChangeRoute, onClickCopy, onClickRemove, onClickPrintLabels, addressBook} = this.props

        const statusHistory = order?.history.filter((entry) => typeof entry.status === 'number')

        const {statusColor, status, subStatus} = Colors.orderStatusColor(order)

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{position: 'relative'}}>
                    <Row style={{alignItems: 'center', marginBottom: 12}}>
                        <Column>
                            <H4>Rit info</H4>

                            <div style={{flex: 1}}/>
                        </Column>

                        {!orderLoading &&
                        <>
                            <IconButton
                                tooltip='Bewerk rit'
                                disabled={statusHistory?.length !== 1}
                                onClick={() => {
                                    this.close()
                                    editOrder(order)
                                }}
                            >
                                <i className='mdi mdi-pencil'/>
                            </IconButton>

                            {order?.templateId &&
                                <IconButton
                                    tooltip='Bewerk herhaalde rit'
                                    onClick={() => {
                                        this.popup.open('Herhaalde rit bewerken', 'Je gaat deze rit en alle toekomstige ritten aanpassen, weet je zeker dat je door wil gaan?', () => {
                                            OrderActions.getTemplate(order.templateId, (err, template) => {
                                                if (err) {
                                                    this.setState({error: err})
                                                    return
                                                }

                                                this.popup.close()
                                                this.close()
                                                editOrder(template, true, order.date)
                                            })
                                        })
                                    }}
                                >
                                    <i className='mdi mdi-calendar-edit'/>
                                </IconButton>
                            }

                            <IconButton
                                tooltip='Kopieer rit'
                                onClick={() => {
                                    this.close()
                                    onClickCopy(order._id)
                                }}
                            >
                                <i className='mdi mdi-content-copy'/>
                            </IconButton>

                            <IconButton
                                tooltip='Print labels'
                                onClick={() => onClickPrintLabels(order._id)}
                            >
                                <i className='mdi mdi-printer'/>
                            </IconButton>

                            <IconButton
                                tooltip='Verwijder rit'
                                disabled={statusHistory?.length !== 1 || order.createdByCustomer || ['cycloonDelivery', 'parcelPickup', 'parcelDelivery', 'retour'].includes(order.type)}
                                onClick={() => onClickRemove(order)}
                            >
                                <i className='mdi mdi-delete'/>
                            </IconButton>

                            <IconButton
                                tooltip='Verstuur bezorgbewijs'
                                disabled={order?.retourId || ![dbCodes.status.afgerond()].includes(order?.status)}
                                onClick={() => this.sendEmailNotificationPopup.open(order, (order) => this.setState({order}))}
                            >
                                <i className='mdi mdi-at'/>
                            </IconButton>
                        </>
                        }

                        <Column>
                            <div style={{flex: 1}}/>

                            <IconButton onClick={this.close.bind(this)}>
                                <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                            </IconButton>
                        </Column>
                    </Row>

                    {orderLoading || !order ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250}}>
                            <Spinner name='ball-clip-rotate' fadeIn='none' color={Colors.textDark}/>
                        </div>:
                        <>
                            <Card style={{marginBottom: 24, padding: 0}}>
                                <Info
                                    customers={customers}
                                    order={order}
                                    onUpdateOrder={(order) => this.setState({order})}
                                />

                                <Row>
                                    <Column style={{marginLeft: 6, marginRight: 6}}>
                                        {order.type !== 'retour' && order.previousId &&
                                        <Alert style={{width: '100%'}} variant='info'>
                                            <P style={{color: Colors.textDark}}>Bij deze rit hoort een voorgaande rit. <a style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.open(order.previousId)}>Klik hier</a> om deze te bekijken.</P>
                                        </Alert>
                                        }

                                        {order.type === 'retour' && order.previousId && !order.previousReseller &&
                                        <Alert style={{width: '100%'}} variant='info'>
                                            <P style={{color: Colors.textDark}}>Dit is een retourrit. <a style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.open(order.previousId)}>Klik hier</a> om de bezorgrit te bekijken.</P>
                                        </Alert>
                                        }

                                        {order.type === 'retour' && order.previousId && order.previousReseller &&
                                        <Alert style={{width: '100%'}} variant='info'>
                                            <P style={{color: Colors.textDark}}>Dit is een retourrit. De bezorgrit is door een partner uitgevoerd.</P>
                                        </Alert>
                                        }

                                        {order.retourId && !order.retourReseller &&
                                        <Alert style={{width: '100%'}} variant='info'>
                                            <div style={{display: 'flex'}}>

                                                <P style={{flex: 1, color: Colors.textDark}}>Voor deze rit is een retourrit aangemaakt. <a style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.open(order.retourId)}>Klik hier</a> om deze te bekijken.</P>

                                                <P style={{color: Colors.textDark}}>
                                                    <a style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.onClickCancelRetour(order.retourId)}>
                                                        <i className='mdi mdi-close-circle'/>
                                                        Annuleer retour
                                                    </a>
                                                </P>

                                            </div>
                                        </Alert>
                                        }

                                        {order.retourId && order.retourReseller &&
                                        <Alert style={{width: '100%'}} variant='info'>
                                            <div style={{display: 'flex'}}>

                                                <P style={{flex: 1, color: Colors.textDark}}>{`Voor deze rit is een retourrit aangemaakt. Deze wordt uitgevoerd door ${order.retourReseller}.`}</P>

                                                <P style={{color: Colors.textDark}}>
                                                    <a style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.onClickCancelRetour(order.retourId)}>
                                                        <i className='mdi mdi-close-circle'/>
                                                        Annuleer retour
                                                    </a>
                                                </P>

                                            </div>
                                        </Alert>
                                        }
                                    </Column>
                                </Row>


                                <Row>
                                    <Column>
                                        <SelectRoute
                                            label='Route'
                                            usersObject={usersObject}
                                            routes={routes}
                                            date={order.date}
                                            value={order.routeId}
                                            disabled={[dbCodes.status.verwacht(), dbCodes.status.aangemaakt(), dbCodes.status.onderweg()].includes(order.status)}
                                            onChange={(event) => {
                                                onChangeRoute(order._id, event, (err, order) => {
                                                    if (err) {
                                                        this.setState({error: err})
                                                    } else {
                                                        this.setState({order})
                                                    }
                                                })
                                            }}
                                        />
                                    </Column>

                                    <Column>
                                        <FlexColumn style={{justifyContent: 'center', alignItems: 'center', background: statusColor, marginBottom: 12, height: 42, marginLeft: 6, marginRight: 6}}>
                                            <S1>{status}</S1>

                                            {subStatus && subStatus !== dbCodes.status[dbCodes.status.geannuleerd()] &&
                                                <P>{subStatus}</P>
                                            }
                                        </FlexColumn>
                                    </Column>
                                </Row>

                                <Row style={{marginBottom: 6}}>
                                    <Column>
                                        <Button
                                            style={{flex: 1}}
                                            variant='outline'
                                            tooltip='Aangeleverd op hub'
                                            icon='mdi mdi-package-variant-closed-check'
                                            disabled={!order.atHubPossible || order.retourId}
                                            onClick={() => this.atHubOrderPopup.open(order, (order) => this.setState({order}))}
                                        />

                                        <Button
                                            style={{flex: 1}}
                                            variant='outline'
                                            tooltip='Herplannen'
                                            icon='mdi mdi-calendar'
                                            disabled={!order.reschedulePossible || order.retourId}
                                            onClick={() => this.rescheduleOrderPopup.open(order, (order) => this.setState({order}))}
                                        />

                                        <Button
                                            style={{flex: 1}}
                                            variant='outline'
                                            tooltip='In bewaring'
                                            icon='mdi mdi-archive'
                                            disabled={!order.onHoldPossible || order.templateId || order.retourId || [dbCodes.status.inBewaring()].includes(order.status) || reseller.isShop}
                                            onClick={() => this.onHoldOrderPopup.open([order._id], (order) => this.setState({order}))}
                                        />

                                        <Button
                                            style={{flex: 1}}
                                            variant='outline'
                                            tooltip='Retour'
                                            icon='mdi mdi-keyboard-return'
                                            disabled={!order.returnPossible || order.type === 'retour' || order.retourId ||reseller.isShop}
                                            onClick={() => this.returnOrderPopup.open([order._id], (order) => this.setState({order}))}
                                        />

                                        {order.type !== 'retour' &&
                                            <DropdownActionButton
                                                style={{flex: 1}}
                                                variant='outline'
                                                icon='mdi mdi-close-circle'
                                                disabled={order.retourId}
                                                options={[
                                                    {label: 'Niet aangeleverd', disabled: !order.notSuppliedPossible, onClick: () => this.notSuppliedOrderPopup.open(order, (order) => this.setState({order}))},
                                                    {label: 'Manco', disabled: !order.mancoPossible, onClick: () => this.mancoOrderPopup.open(order, (order) => this.setState({order}))},
                                                    {label: 'Annuleren', disabled: !order.cancelPossible, onClick: () => this.cancelOrderPopup.open(order, (order) => this.setState({order}))}
                                                ]}
                                            />
                                        }

                                        {order.type === 'retour' &&
                                            <Button
                                                style={{flex: 1}}
                                                variant='outline'
                                                tooltip='Annuleren'
                                                icon='mdi mdi-close-circle'
                                                disabled={[dbCodes.status.afgerond()].includes(order.status)}
                                                onClick={this.onClickCancelRetour.bind(this, order._id)}
                                            />
                                        }
                                    </Column>

                                    <Column>
                                        <Button
                                            style={{flex: 1}}
                                            variant='outline'
                                            label='Ophalen / Bezorgen'
                                            disabled={order.colli.every((collo) => collo.status === 'expected') || [dbCodes.status.geannuleerd()].includes(order.status) || order.retourId}
                                            onClick={() => this.pickupDeliverPopup.open(order, reseller, customers?.[order.customer], (order) => this.setState({order}))}
                                        />
                                    </Column>
                                </Row>
                            </Card>

                            <S1 style={{marginBottom: 12}}>Adressen</S1>

                            <div style={{width: '100%', marginBottom: 24}}>
                                {order.addresses.map((address, addressIndex) => {
                                    const addressColli = order.colli.filter((collo) => (address.type === 'pickup' && collo.pickupAddressIndex === address.index) || (address.type === 'delivery' && collo.deliveryAddressIndex === address.index))

                                    return (
                                        <div key={`address${addressIndex}`}>
                                            <Address
                                                key={`address${addressIndex}`}
                                                reseller={reseller}
                                                order={order}
                                                addressIndex={addressIndex}
                                                addressBook={addressBook}
                                                onUpdateOrder={(order) => this.setState({order})}
                                                onClickPrintLabels={onClickPrintLabels}
                                            />

                                            {addressColli.length > 0 &&
                                            <Card style={{marginTop: -13, marginBottom: 24, padding: 0}}>
                                                {addressColli.map((collo, index) => {
                                                    return (
                                                        <Collo
                                                            key={`Collo${collo.index}`}
                                                            index={index}
                                                            colli={addressColli}
                                                            order={order}
                                                            addressIndex={addressIndex}
                                                            colloIndex={collo.index}
                                                            onUpdateOrder={(order) => this.setState({order})}
                                                            onClickPrintLabels={address.type === 'delivery' && onClickPrintLabels}
                                                            disabled={['cancelled'].includes(collo.status)}
                                                        />
                                                    )
                                                })}
                                            </Card>
                                            }

                                        </div>
                                    )
                                })}
                            </div>

                            {order.monitorTemperature && order.temperatureData && !!order.temperatureData.length &&
                            <>
                                <S1 style={{marginBottom: 12}}>Temperatuur</S1>
                                <Temperature reseller={reseller} order={order}/>
                            </>
                            }

                            <Administration
                                reseller={reseller}
                                order={order}
                                onUpdateOrder={(order) => this.setState({order})}
                            />

                            <MoreOptions
                                reseller={reseller}
                                order={order}
                                onUpdateOrder={(order) => this.setState({order})}
                            />

                            <S1 style={{marginBottom: 12}}>Historie</S1>

                            <Row>
                                <Input
                                    label='Opmerking toevoegen'
                                    value={historyComment}
                                    style={{width: 400}}
                                    onChange={(event) => this.setState({historyComment: event.target.value})}
                                    onKeyUp={(event) => {
                                        if (event.key === 'Enter' && historyComment) {
                                            this.onAddHistoryComment()
                                        }
                                    }}
                                />

                                <IconButton
                                    icon='mdi mdi-check'
                                    onClick={this.onAddHistoryComment.bind(this)}
                                    disabled={!historyComment}
                                />
                            </Row>

                            <ScrollView style={{maxHeight: 250}}>
                                {[...order.history].reverse().map((entry, index) => {
                                    let deviationMeters

                                    if (entry.location?.lat && order.addresses[entry.addressIndex]?.position?.lat) {
                                        deviationMeters = parseInt(calcDistance(entry.location, order.addresses[entry.addressIndex].position)) - parseInt(entry.location.accuracy)
                                    }

                                    return (
                                        <Row key={`history${index}`} style={{marginBottom: 3}}>

                                            <i className='mdi mdi-circle-small' style={{marginRight: 6, fontSize: 16, color: Colors.grey60}}/>

                                            <Column style={{flex: 1, flexDirection: 'column'}}>

                                                <Row style={{alignItems: 'center'}}>
                                                    {entry.type === 'invoice' ?
                                                        <Link onClick={() => history.push('/facturatie', {invoiceId: entry.invoiceId})}>{entry.description}</Link> :
                                                        <P>{entry.description || (typeof entry.status === 'number' ? dbCodes.status[entry.status] : '')}</P>
                                                    }
                                                </Row>

                                                {entry.colli?.length > 0 &&
                                                <>
                                                    {entry.colli.map((collo, index) => {
                                                        const {status, reason, statusColor} = Colors.colloStatusColor(entry.type, collo)
                                                        const isScanned = entry?.scanned?.findIndex((barcode) => barcode === collo.barcode) > -1

                                                        return (
                                                            <Row key={`collo${index}`}>
                                                                <Column>
                                                                    <i className='mdi mdi-circle' style={{marginRight: 12, marginTop: 4, fontSize: 10, color: statusColor}}/>
                                                                    {isScanned && <i className='mdi mdi-barcode-scan ' style={{marginRight: 6}}/>}
                                                                    <P>{`${reason || status}${collo.reasonSpecification ? ` - ${collo.reasonSpecification}` : ''}: Collo ${collo.index + 1}${collo.barcode ? ` - ${collo.barcode}` : ''}`}</P>
                                                                </Column>
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                                }

                                                {entry.comment &&
                                                    <S2 style={{}} >{entry.comment}</S2>
                                                }
                                            </Column>

                                            <Column style={{width: 180, marginTop: -10}}>
                                                {order.addresses[entry.addressIndex]?.position &&
                                                    <IconButton onClick={() => this.setState({entryPopup: entry, entryPopupView: 'location'})} style={{color: !entry.location ? Colors.grey60 : (reseller.settings.deliveryRadiusWarning && deviationMeters >= (reseller.settings.maxDeliveryRadius || 40)) ? Colors.errorBright : Colors.buttonSolid}}>
                                                        <i className='mdi mdi-map-marker-radius'/>
                                                    </IconButton>
                                                }

                                                {(entry.signee || entry.signature || entry.signatureId) &&
                                                    <IconButton onClick={() => this.onClickOpenSignature(entry)}>
                                                        {(entry.signature || entry.signatureId) ?
                                                            <i className="mdi mdi-fountain-pen-tip"/> :
                                                            <i className="mdi mdi-signature-text"/>
                                                        }
                                                    </IconButton>
                                                }

                                                {entry.documentId &&
                                                    <IconButton onClick={() => this.setState({entryPopup: entry, entryPopupView: 'documentId'})}>
                                                        <i className="mdi mdi-card-account-details-outline"/>
                                                    </IconButton>
                                                }

                                                {entry.verificationCode &&
                                                    <IconButton onClick={() => this.setState({entryPopup: entry, entryPopupView: 'verificationCode'})}>
                                                        <i className="mdi mdi-shield-key-outline"/>
                                                    </IconButton>
                                                }

                                                {(entry.image || entry.imageId) &&
                                                    <IconButton onClick={() => this.onClickOpenImage(entry)}>
                                                        <i className="mdi mdi-image-outline"/>
                                                    </IconButton>
                                                }
                                            </Column>

                                            <Column style={{width: 200}}>
                                                <S2>{`${entry.who} | ${entry.timestamp ? moment(entry.timestamp).format('DD-MM-YYYY HH:mm:ss') : entry.time}`}</S2>
                                            </Column>
                                        </Row>
                                    )
                                })}
                            </ScrollView>
                        </>
                    }

                    {message &&
                        <Alert variant="success">{message}</Alert>
                    }
                    {error &&
                        <Alert variant="danger">{error}</Alert>
                    }


                    {orderError &&
                        <Alert variant="danger">{orderError}</Alert>
                    }

                    {entryPopup && (
                        <Card style={{position: 'absolute', bottom: -12, left: 800, width: 400, overflowY: 'auto', padding: '12px 12px 24px 12px', background: Colors.backgroundNeutral}}>
                            <Row style={{alignItems: 'center'}}>
                                <H4>
                                    {entryPopupView === 'location' && 'Locatie'}
                                    {entryPopupView === 'signature' && 'Handtekening'}
                                    {entryPopupView === 'documentId' && 'Document ID (laatste 4 tekens)'}
                                    {entryPopupView === 'verificationCode' && 'Verificatiecode'}
                                    {entryPopupView === 'image' && 'Foto'}
                                </H4>

                                <div style={{flex: 1}}/>

                                <IconButton onClick={() => this.setState({entryPopup: '', entryPopupView: ''})}>
                                    <i style={{color: Colors.buttonSolid}} className="mdi mdi-close"/>
                                </IconButton>
                            </Row>

                            <Row style={{marginTop: 24, marginBottom: 24}}>

                                <i className='mdi mdi-circle' style={{marginRight: 6, marginTop: 3, fontSize: 12, color: Colors.statusIcon[entryPopup.status] || Colors.grey40}}/>

                                <Column style={{flex: 1, flexDirection: 'column'}}>

                                    <Row style={{alignItems: 'center'}}>
                                        {entryPopup.type === 'invoice' ?
                                            <Link onClick={() => history.push('/facturatie', {invoiceId: entryPopup.invoiceId})}>{entryPopup.description}</Link> :
                                            <P>{entryPopup.description || (typeof entryPopup.status === 'number' ? dbCodes.status[entryPopup.status] : '')}</P>
                                        }
                                    </Row>

                                </Column>

                                <Column style={{width: 200}}>
                                    <S2>{`${entryPopup.who} | ${entryPopup.timestamp ? moment(entryPopup.timestamp).format('DD-MM-YYYY HH:mm:ss') : entryPopup.time}`}</S2>
                                </Column>

                            </Row>

                            {entryPopupView === 'location' && (
                                <>
                                    {entryPopup.location ?
                                        <>
                                            <S1>Afwijking met adres</S1>
                                            <P>{parseInt(calcDistance(entryPopup.location, order.addresses[entryPopup.addressIndex].position))} m ±{parseInt(entryPopup.location.accuracy)} m</P>

                                            <br/>
                                            <Button
                                                variant='text'
                                                onClick={this.onClickLocation.bind(this, entryPopup.location)}
                                            >
                                                Bekijk op kaart
                                            </Button>
                                        </> :
                                        <P>Locatie is niet beschikbaar</P>
                                    }
                                </>
                            )}

                            {entryPopupView === 'signature' && (
                                <>
                                    {entryPopup?.signee &&
                                    <>
                                        <S1>Getekend door</S1>
                                        <P>{entryPopup?.signee}</P>
                                    </>
                                    }

                                    {entryPopup?.signature &&
                                    <>
                                        <img
                                            style={{height: 200, width: 200, marginRight: 12, objectFit: 'contain'}}
                                            src={entryPopup?.signature}
                                        />
                                        <IconButton onClick={this.onClickViewImage.bind(this, entryPopup?.signature)} >
                                            <i className='mdi mdi-magnify-plus-outline'/>
                                        </IconButton>
                                    </>
                                    }
                                </>
                            )}

                            {entryPopupView === 'documentId' && (
                                <Row style={{alignItems: 'center'}}>
                                    <i style={{marginRight: 6}} className='mdi mdi-card-account-details-outline'/>
                                    <P>{entryPopup.documentId}</P>
                                </Row>
                            )}

                            {entryPopupView === 'verificationCode' && (
                                <Row style={{alignItems: 'center'}}>
                                    <i style={{marginRight: 6}} className='mdi mdi-shield-key-outline'/>
                                    <P>{entryPopup.verificationCode}</P>
                                </Row>
                            )}

                            {entryPopupView === 'image' && (
                                <>
                                    <img
                                        style={{height: 200, width: '100%', objectFit: 'contain'}}
                                        src={entryPopup.image}
                                    />
                                    <IconButton onClick={this.onClickViewImage.bind(this, entryPopup.image)} >
                                        <i className='mdi mdi-magnify-plus-outline'/>
                                    </IconButton>
                                </>
                            )}
                        </Card>
                    )}
                </div>

                <NotSuppliedOrderPopup ref={(ref) => this.notSuppliedOrderPopup = ref}/>
                <MancoOrderPopup ref={(ref) => this.mancoOrderPopup = ref}/>
                <CancelOrderPopup ref={(ref) => this.cancelOrderPopup = ref}/>
                <AtHubOrderPopup ref={(ref) => this.atHubOrderPopup = ref}/>
                <RescheduleOrderPopup ref={(ref) => this.rescheduleOrderPopup = ref}/>
                <ReturnOrderPopup ref={(ref) => this.returnOrderPopup = ref}/>
                <OnHoldOrderPopup ref={(ref) => this.onHoldOrderPopup = ref}/>
                <PickupDeliverPopup ref={(ref) => this.pickupDeliverPopup = ref}/>
                <SendEmailNotificationPopup ref={(ref) => this.sendEmailNotificationPopup = ref}/>
                <Popup ref={(ref) => this.popup = ref}/>
            </Modal>
        )
    }
}

export default ViewOrderModal
