import React from 'react'
import _ from 'underscore'
import Leaflet from './Leaflet.js'

import OrderActions from '../../actions/OrderActions.js'

import dbCodes from '../../../server/dbCodes.js'
import {Button, Card, Colors, FlexColumn, FlexRow, Icon, Link, ListItem, Popover, Toggle, Spinner} from '../UI/index.js'

const randomPoints = []

for (let i=0; i < 0; i++) {
    const lat = Math.round((Math.random()*3 + 51) * 1000)/1000
    const lng = Math.round((Math.random()*3 + 4) * 1000)/1000

    randomPoints.push({lat, lng})
}

export default class Map extends React.PureComponent {
    constructor(props) {
        super(props)

        this.mapState = {
            map: null,
            mouseDown: false,
            mousePosition: null,
            gribBoundingBox: null,
            markers: [],
            polylines: []
        }

        this.state = {
            shiftPressed: false
        }

        this.onKeyDown = this.onKeyDown.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
    }

    componentDidMount() {
        this.initMap()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keyup', this.onKeyUp)
    }

    componentDidUpdate(prevProps) {
        const {date, orders, routes, selectedOrders, activeRouteTab, options, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap, focusedRouteId, selectedRouteId, showRoutePanel, showOrderPanel} = this.props

        if (prevProps.showRoutePanel !== showRoutePanel || prevProps.showOrderPanel !== showOrderPanel || prevProps.focusedRouteId !== focusedRouteId || prevProps.selectedRouteId !== selectedRouteId) {
            setTimeout(() => {
                this.mapState.map.invalidateSize()
            }, 200)
        }


        if (prevProps.date !== date ||prevProps.orders !== orders || prevProps.routes !== routes || prevProps.selectedOrders !== selectedOrders || prevProps.activeRouteTab !== activeRouteTab || prevProps.options !== options || prevProps.showOrdersOnMap !== showOrdersOnMap || prevProps.showCompletedAddressesOnMap !== showCompletedAddressesOnMap ||prevProps.showRoutesOnMap !== showRoutesOnMap || prevProps.showStopNumbersOnMap !== showStopNumbersOnMap || prevProps.focusedRouteId !== focusedRouteId) {
            this.updateMap(date, orders, routes, selectedOrders, activeRouteTab, options, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap, prevProps.focusedRouteId, focusedRouteId)
        }
    }

    onKeyDown(event) {
        if (event.which === 16) { // shift
            this.pressShift(true)
        }
    }

    onKeyUp(event) {
        if (event.which === 16) {
            this.pressShift(false)

            this.mapState.mouseDown = false

            this.mapState.mousePosition = null
            if (this.mapState.gribBoundingBox) {
                this.mapState.gribBoundingBox.remove()
                this.mapState.gribBoundingBox = null
            }
        }
    }

    initMap() {
        const {reseller, date, orders, routes, selectedOrders, activeRouteTab, options, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap} = this.props
        let {map} = this.mapState

        document.addEventListener('keydown', this.onKeyDown)
        document.addEventListener('keyup', this.onKeyUp)

        map = Leaflet.map('map', {
            preferCanvas: true,
            boxZoom: false,
            updateWhenZooming: false,
            updateWhenIdle: true,
            attributionControl: false,
            zoomControl: false
        }).setView(reseller.settings.companyAddress.position || {lat: 52.2076823, lng: 5.1584931}, 13)

        Leaflet.tileLayer(`${window.location.origin}/api/mapbox/styles/v1/{account}/{id}/tiles/{z}/{x}/{y}`, {
            maxZoom: 18,
            updateWhenZooming: false,
            updateWhenIdle: true,
            account: 'veloyd',
            id: 'cka2lgtlz0huj1iln27ujuqim',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(map)

        Leaflet.control.scale().addTo(map)

        map.on('click', () => {
            const {onChange, selectedOrders} = this.props

            if (!this.state.shiftPressed && selectedOrders.length) {
                onChange([])
            }
        })

        map.on('mousedown', (event) => {
            if (this.state.shiftPressed) {
                event.originalEvent.stopPropagation()

                this.mapState.mouseDown = true
                this.mapState.mousePosition = Leaflet.latLng(event.latlng)
            }
        })

        map.on('mousemove', (event) => {
            if (this.mapState.mouseDown && this.state.shiftPressed) {
                const corner = Leaflet.latLng(event.latlng)
                const bounds = Leaflet.latLngBounds(this.mapState.mousePosition, corner)

                if (this.mapState.gribBoundingBox) {
                    this.mapState.gribBoundingBox.setBounds(bounds)
                } else {
                    document.getSelection().removeAllRanges() // important, prevent selection of html elements
                    this.mapState.gribBoundingBox = Leaflet.rectangle(bounds, {
                        color: Colors.backgroundDark,
                        weight: 0.4
                    })

                    this.mapState.gribBoundingBox.addTo(map)
                }
            }
        })

        map.on('mouseup', (event) => {
            const {markers} = this.mapState
            const {onChange, selectedOrders} = this.props

            if (this.state.shiftPressed && this.mapState.mouseDown && this.mapState.gribBoundingBox) {
                const selected = []

                markers.map((marker) => {
                    if (this.mapState.gribBoundingBox.getBounds().contains(marker.mapMarker.getLatLng())) {
                        selected.push(...marker.orderIds)
                    }
                })

                this.mapState.mouseDown = false
                this.mapState.mousePosition = null
                this.mapState.gribBoundingBox.remove()
                this.mapState.gribBoundingBox = null

                if (selected.length) {
                    selectedOrders.push(...selected)
                    onChange(_.unique(selectedOrders))
                }
            } else {
                this.mapState.mouseDown = false
                this.mapState.mousePosition = null
                if (this.mapState.gribBoundingBox) {
                    this.mapState.gribBoundingBox.remove()
                    this.mapState.gribBoundingBox = null
                }
            }
        })

        this.mapState.map = map

        this.updateMap(date, orders, routes, selectedOrders, activeRouteTab, options, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap)
    }

    updateMap(date, orders, routes, selectedOrders, activeRouteTab, options, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap, oldFocusedRouteId, focusedRouteId) {
        setTimeout(() => {
            const start = performance.now()

            let {map, markers, polylines} = this.mapState
            const {reseller, centerViewOnSelected} = this.props

            // const startClear = performance.now()

            markers.map((marker) => marker.mapMarker.remove())

            polylines.map((polyline) => polyline.remove())
            markers = []
            polylines = []

            // const endClear = performance.now()
            // console.log(`clear map: ${parseInt(endClear - startClear)} ms`)


            const positions = {}

            // Draw Orders
            // const startOrders = performance.now()

            const routeHasOrders = {}

            if (activeRouteTab === 'open') {
                Object.values(orders).map((order) => {
                    if ([dbCodes.status.geannuleerd()].includes(order.status)) {
                        return
                    }

                    if (order.date !== date) {
                        return
                    }

                    const orderPath = []

                    order.addresses.map((address) => {
                        if (!address.position || address.hasArrived) {
                            return
                        }

                        if (order.routeId) {
                            const route = routes[order.routeId]

                            if (route && !route.hidden && showRoutesOnMap && (!focusedRouteId || route._id === focusedRouteId)) {
                                positions[`${address.position.lat},${address.position.lng}`] = positions[`${address.position.lat},${address.position.lng}`] || []

                                positions[`${address.position.lat},${address.position.lng}`].push({
                                    orderId: order._id,
                                    position: address.position,
                                    color: route?.color,
                                    radius: (route._id === focusedRouteId || showStopNumbersOnMap) ? 10 : 6,
                                    opacity: (options.showExpectedOnMap && address.isExpected) ? 0.5 : 1,
                                    title: `${address.name}, ${address.street} ${address.nr}${address.addition}`,
                                    name: address.name,
                                    street: address.street,
                                    nr: address.nr,
                                    addition: address.addition,
                                    postalCode: address.postalCode,
                                    city: address.city,
                                    startTime: address.startTime,
                                    endTime: address.endTime,
                                    instructions: address.instructions,
                                    label: (route._id === focusedRouteId || showStopNumbersOnMap) ? address.stopNumber : '',
                                    status: address.status
                                })

                                routeHasOrders[order.routeId] = true
                            }
                        } else if (showOrdersOnMap) {
                            orderPath.push(address.position)

                            positions[`${address.position.lat},${address.position.lng}`] = positions[`${address.position.lat},${address.position.lng}`] || []

                            positions[`${address.position.lat},${address.position.lng}`].push({
                                orderId: order._id,
                                position: address.position,
                                color: Colors[address.type],
                                opacity: options.showExpectedOnMap && order.status === dbCodes.status.verwacht() ? 0.5 : 1,
                                radius: showStopNumbersOnMap ? 10 : 6,
                                title: `${address.startTime}-${address.endTime} ${address.name}, ${address.street} ${address.nr}${address.addition} ${address.city}`,
                                name: address.name,
                                street: address.street,
                                nr: address.nr,
                                addition: address.addition,
                                postalCode: address.postalCode,
                                city: address.city,
                                startTime: address.startTime,
                                endTime: address.endTime,
                                instructions: address.instructions,
                                label: showStopNumbersOnMap ? (address.type === 'pickup' ? 'P' : 'D') : ''
                            })
                        }
                    })


                    if (orderPath.length > 1) {
                        const polyline = Leaflet.polyline(orderPath, {
                            color: Colors.grey60,
                            weight: 2,
                            opacity: 0.4
                        })

                        polyline.addTo(map)

                        polylines.push(polyline)
                    }
                })
            }

            // const endOrders = performance.now()
            // console.log(`orders.map: ${parseInt(endOrders - startOrders)} ms`)


            // Draw routes
            // const startRoutes = performance.now()

            _.keys(routes).map((routeId) => {
                const route = routes[routeId]

                if (route.date !== date || route.hidden || (focusedRouteId && route._id !== focusedRouteId) || (route.completed && activeRouteTab !== 'completed') || (!route.completed && activeRouteTab === 'completed')) {
                    return
                }


                if (route.startPoint?.position) {
                    positions[`${route.startPoint.position.lat},${route.startPoint.position.lng}`] = positions[`${route.startPoint.position.lat},${route.startPoint.position.lng}`] || []

                    positions[`${route.startPoint.position.lat},${route.startPoint.position.lng}`].push({
                        position: route.startPoint.position,
                        color: Colors.successBright,
                        title: `${route.startPoint.street} ${route.startPoint.nr}${route.startPoint.addition}`
                    })
                }


                if (route.endPoint?.position) {
                    positions[`${route.endPoint.position.lat},${route.endPoint.position.lng}`] = positions[`${route.endPoint.position.lat},${route.endPoint.position.lng}`] || []

                    positions[`${route.endPoint.position.lat},${route.endPoint.position.lng}`].push({
                        position: route.endPoint.position,
                        color: Colors.grey60,
                        title: `${route.endPoint.street} ${route.endPoint.nr}${route.endPoint.addition}`
                    })
                }

                if (showCompletedAddressesOnMap || activeRouteTab === 'completed') {
                    route.completedAddresses.map((address) => {
                        if (!address.position) {
                            return
                        }

                        positions[`${address.position.lat},${address.position.lng}`] = positions[`${address.position.lat},${address.position.lng}`] || []

                        positions[`${address.position.lat},${address.position.lng}`].push({
                            orderId: address.orderId,
                            position: address.position,
                            color: address.success ? Colors.successBright : Colors.warningBright,
                            radius: showStopNumbersOnMap ? 10 : 6,
                            opacity: 1,
                            title: `${address.name}, ${address.street} ${address.nr}${address.addition}`,
                            name: address.name,
                            street: address.street,
                            nr: address.nr,
                            addition: address.addition,
                            postalCode: address.postalCode,
                            city: address.city,
                            startTime: address.startTime,
                            endTime: address.endTime,
                            instructions: address.instructions,
                            label: showStopNumbersOnMap ? address.stopNumber : '',
                            status: address.status
                        })
                    })


                    if (route.completedGeometry) {
                        const coordinates = route.completedGeometry.coordinates.map((coords)=> new Leaflet.LatLng(coords[1], coords[0]))
                        const polyline = new Leaflet.Polyline(coordinates, {
                            color: route.color,
                            weight: 2,
                            opacity: 0.9
                        })

                        polyline.addTo(map)
                        polylines.push(polyline)

                        if (route._id === focusedRouteId && oldFocusedRouteId !== focusedRouteId) {
                            map.fitBounds(coordinates)
                        }
                    }
                }

                if (route.geometry && !route.completed && showRoutesOnMap && activeRouteTab === 'open' && routeHasOrders[route._id]) {
                    const coordinates = route.geometry.coordinates.map((coords)=> new Leaflet.LatLng(coords[1], coords[0]))

                    const polyline = new Leaflet.Polyline(coordinates, {
                        color: route.color,
                        weight: 2,
                        opacity: 0.9
                    })

                    polyline.addTo(map)
                    polylines.push(polyline)

                    if (route._id === focusedRouteId && oldFocusedRouteId !== focusedRouteId) {
                        map.fitBounds(coordinates)
                    }
                }
            })

            // const endRoutes = performance.now()
            // console.log(`routes.map: ${parseInt(endRoutes - startRoutes)} ms`)


            const resellerAddress = {
                name: reseller.settings.companyAddress.name,
                street: reseller.settings.companyAddress.street,
                nr: reseller.settings.companyAddress.nr,
                addition: reseller.settings.companyAddress.addition,
                postalCode: reseller.settings.companyAddress.postalCode,
                city: reseller.settings.companyAddress.city,
                position: reseller.settings.companyAddress.position

            }

            if (resellerAddress.position) {
                positions[`${resellerAddress.position.lat},${resellerAddress.position.lng}`] = positions[`${resellerAddress.position.lat},${resellerAddress.position.lng}`] || []

                positions[`${resellerAddress.position.lat},${resellerAddress.position.lng}`].push({
                    position: resellerAddress.position,
                    color: Colors.successBright,
                    title: `${resellerAddress.name}, ${resellerAddress.street} ${resellerAddress.nr}${resellerAddress.addition}`
                })
            }


            // const startRandomPoints = performance.now()
            // randomPoints.map((point) => {
            //     positions[`${point.lat},${point.lng}`] = [{
            //         position: point,
            //         color: Colors.buttonSolid,
            //         title: `${point.lat}, ${point.lng}`,
            //         radius: 6,
            //         label: 'r'
            //     }]
            // })

            // const endRandomPoints = performance.now()
            // console.log(`randomPoints.map: ${parseInt(endRandomPoints - startRandomPoints)} ms`)


            // const startPositions = performance.now()

            Object.keys(positions).map((key) => {
                const addresses = positions[key]
                const orderIds = _.filter(_.pluck(addresses, 'orderId'), (id) => !!id)

                const isSelected = _.some(orderIds, (id) => {
                    return selectedOrders.indexOf(id) > -1
                })

                const marker = {orderIds}

                const allAddressesAreExpected = addresses.every((address) => address.status === dbCodes.status.verwacht())

                const mapMarker = Leaflet.circleMarker(addresses[0].position, {
                    text: addresses[0].label,
                    fontColor: Colors.isDarkBackground(addresses[0].color) ? Colors.white : Colors.textDark,
                    radius: addresses[0].radius || 10,
                    color: isSelected ? 'black' : 'white',
                    fillColor: allAddressesAreExpected ? Colors.deliveryExpected : addresses[0].color,
                    weight: 1,
                    fillOpacity: addresses[0].opacity || 1.0
                })


                if (addresses[0].orderId) {
                    mapMarker.bindTooltip(
                        `<div style='max-width: 300px; max-width: fit-content;'>
                        <span>${addresses[0].name}</span>
                        <br>
                        <span>${addresses[0].street} ${addresses[0].nr}${addresses[0].addition}</span>
                        <br>
                        <span>${addresses[0].postalCode} ${addresses[0].city}</span>
                        <br>
                        <span>${addresses[0].startTime} - ${addresses[0].endTime}</span>
                        <hr>
                        <i style='white-space: normal;'>${addresses[0].instructions}</i>
                        </div>`
                    )
                } else {
                    mapMarker.bindTooltip(`<span>${addresses[0].title}</span>`)
                }


                if (marker.orderIds.length) {
                    mapMarker.on('click', (event) => {
                        const {onChange, onClick, selectedOrders} = this.props

                        if (this.state.shiftPressed) {
                            if (isSelected) {
                                onChange(_.difference(selectedOrders, marker.orderIds))
                            } else {
                                onChange([...selectedOrders, ...marker.orderIds])
                            }
                        } else {
                            onClick(marker.orderIds)
                        }
                    })
                }

                mapMarker.addTo(map)
                marker.mapMarker = mapMarker
                markers.push(marker)
            })

            // const endPositions = performance.now()
            // console.log(`positions.map: ${parseInt(endPositions - startPositions)} ms`)

            if (centerViewOnSelected) {
                let bounds = markers.map(({orderIds, mapMarker}) => orderIds.includes(selectedOrders[0]) ? [mapMarker._latlng.lat, mapMarker._latlng.lng] : undefined)

                bounds = bounds.filter(Boolean)
                map.fitBounds(bounds)
            }


            this.mapState.markers = markers
            this.mapState.polylines = polylines

            const end = performance.now()

            console.log(`updateMap: ${end - start} ms`)
        }, 1)
    }

    pressShift(shiftPressed) {
        this.setState({shiftPressed})

        if (shiftPressed) {
            document.getElementById('map').style.cursor = 'crosshair'
        } else {
            document.getElementById('map').style.cursor = 'grab'
        }
    }

    render() {
        const {shiftPressed} = this.state
        const {loading, focusedRouteId, someRouteHidden, options, showOrdersOnMap, showCompletedAddressesOnMap, showRoutesOnMap, showStopNumbersOnMap} = this.props

        return (
            <div id='map' style={{position: 'relative', cursor: 'grab', zIndex: 0, width: '100%', height: '100%'}}>
                <FlexColumn style={{position: 'absolute', left: 6, top: 6, zIndex: 99999999, overflow: 'default'}}>
                    <Card shadow='true' style={{padding: 0, width: 'fit-content', marginBottom: 24}}>
                        <Button
                            style={{marginLeft: 0, marginRight: 0}}
                            variant='text'
                            icon='mdi mdi-plus'
                            onClick={() => this.mapState.map.zoomIn()}
                        />

                        <Button
                            style={{marginLeft: 0, marginRight: 0}}
                            variant='text'
                            icon='mdi mdi-minus'
                            onClick={() => this.mapState.map.zoomOut()}
                        />
                    </Card>

                    <Card shadow='true' style={{padding: 0, width: 'fit-content'}}>
                        <Button
                            style={{marginLeft: 0, marginRight: 0}}
                            variant='text'
                            icon='mdi mdi-select-drag'
                            tooltip='Selecteren (Shift)'
                            tooltipPlacement='right'
                            selected={shiftPressed}
                            onClick={() => this.pressShift(!shiftPressed)}
                        />

                        <Button
                            style={{marginLeft: 0, marginRight: 0}}
                            variant='text'
                            icon='mdi mdi-hand-back-right-outline'
                            tooltip='Kaart bewegen'
                            tooltipPlacement='right'
                            selected={!shiftPressed}
                            onClick={() => this.pressShift(!shiftPressed)}
                        />

                        <Popover
                            ref={(ref) => this.popover = ref}
                            content={
                                <div style={{padding: 6, width: 'fit-content'}} onClick={(event) => event.stopPropagation()}>
                                    {this.props.activeRouteTab === 'open' &&
                                    <>
                                        <Toggle
                                            label='Ongeplande adressen'
                                            checked={showOrdersOnMap}
                                            onChange={OrderActions.setShowOrdersOnMap}
                                        />
                                        <Toggle
                                            label='Adressen in routes'
                                            checked={showRoutesOnMap}
                                            onChange={OrderActions.setShowRoutesOnMap}
                                        />
                                        <Toggle
                                            label='Afgeronde adressen'
                                            checked={showCompletedAddressesOnMap}
                                            onChange={OrderActions.setShowCompletedAddresses}
                                        />
                                    </>
                                    }

                                    <Toggle
                                        label='Verwachte adressen'
                                        checked={options.showExpectedOnMap}
                                        onChange={() => OrderActions.setMapOptions({showExpectedOnMap: !options.showExpectedOnMap})}
                                    />

                                    <Toggle
                                        style={{marginBottom: 0}}
                                        label='Stopnummers'
                                        checked={showStopNumbersOnMap}
                                        onChange={OrderActions.setShowStopNumbersOnMap}
                                    />
                                </div>
                            }
                        >
                            <Button
                                style={{marginLeft: 0, marginRight: 0}}
                                variant='text'
                                tooltip='Weergaveopties'
                                tooltipPlacement='right'
                                icon='mdi mdi-layers-outline'
                            />
                        </Popover>
                    </Card>
                </FlexColumn>

                {loading &&
                    <FlexColumn style={{position: 'absolute', alignItems: 'center', justifyContent: 'center', left: 0, top: 0, width: '100%', height: '100%', zIndex: 99999999, overflow: 'default', background: 'white', opacity: 0.5}}>
                        <Spinner name='ball-clip-rotate' fadeIn='none'/>
                    </FlexColumn>
                }

                <FlexRow style={{position: 'absolute', left: 0, top: 6, zIndex: 99999999, display: 'flex', justifyContent: 'center'}}>
                    {someRouteHidden && !focusedRouteId &&
                        <Button
                            style={{marginLeft: 24}}
                            label='Toon alle verborgen routes'
                            onClick={() => OrderActions.resetRoutesHidden()}
                        />
                    }

                    {focusedRouteId &&
                        <Button
                            style={{marginLeft: 24}}
                            label='Reset focus'
                            onClick={() => OrderActions.setRouteFocus('')}
                        />
                    }
                </FlexRow>

                <img src='/images/mapboxLogo.png' style={{position: 'absolute', left: 100, bottom: 6, height: 20, zIndex: 99999, overflow: 'hidden'}}/>

                <div style={{position: 'absolute', right: 6, bottom: 0, zIndex: 99999}}>
                    <Popover
                        placement='top-left'
                        content={
                            <div>
                                <ListItem size='sm'><Link href='https://www.mapbox.com/about/maps/'>Leaflet</Link></ListItem>
                                <ListItem size='sm'><Link href='https://www.mapbox.com/about/maps/'>© Mapbox</Link></ListItem>
                                <ListItem size='sm'><Link href='http://www.openstreetmap.org/copyright'>© OpenStreetMap</Link></ListItem>
                                <ListItem size='sm'><Link href='https://www.mapbox.com/map-feedback/'>Improve this map</Link></ListItem>
                            </div>
                        }
                    >
                        <Icon icon='mdi mdi-information-outline' style={{color: 'white', fontSize: 20}}/>
                    </Popover>
                </div>
            </div>
        )
    }
}
