import time from '../../../server/utils/time.js'
import Colors from '../UI/Colors.js'


export default
/**
 *
 * @param {Reseller} reseller
 *
 * @returns {Route}
 */
(reseller) => {
    const defaultVehicleType = reseller?.settings.vehicleTypes.find((v) => v._id === reseller.settings.defaultVehicle)

    const route = {
        date: time.today(),
        messengerId: '',
        color: Colors.routeColors[Math.floor(Math.random() * Colors.routeColors.length)],
        name: '',
        plannedStartTime: '',
        startPoint: reseller?.settings ? {...reseller.settings.companyAddress} : {name: '', street: '', nr: '', addition: '', postalCode: '', city: ''},
        endPoint: reseller?.settings ? {...reseller.settings.companyAddress} : {name: '', street: '', nr: '', addition: '', postalCode: '', city: ''},
        vehicleType: defaultVehicleType?.routeProfile || 'bike',
        vehicleTypeId: defaultVehicleType?._id || '',
        vehicleId: '',
        parseInt: reseller?.settings.parseInt || 0,
        stopTime: defaultVehicleType?.stopTime || reseller?.settings.stopTime || 180,
        instructions: '',
        optimizeForTimeslots: !!reseller?.settings.optimizeForTimeslots,
        adjustableByMessenger: !!reseller?.settings.adjustableByMessenger,
        completedAddresses: [],
        completedColli: [],
        orderIds: []
    }

    if (defaultVehicleType?.speedDifferenceEnabled) {
        route.customSpeed = true
        const speedDifference = defaultVehicleType.speedDifferenceMode === 'faster' ? -parseInt(defaultVehicleType.speedDifference) : parseInt(defaultVehicleType.speedDifference)
        route.speedDifference = speedDifference
    }


    return JSON.parse(JSON.stringify(route))
}
