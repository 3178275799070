import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import trackTraceEmailTemplate from '../../../../server/functions/mail/templates/trackTraceEmailTemplate.js'
import cancelEmailTemplate from '../../../../server/functions/mail/templates/cancelEmailTemplate.js'
import MailTemplate from '../../../components/UI/MailTemplate.js'
import {
    Alert,
    Colors,
    Editor,
    EmailPreview,
    H3,
    Panel,
    Input,
    Select,
    Toggle,
    Tab,
    Tabs
} from '../../../components/UI/index.js'
import retourConfirmationTemplate from '../../../../server/functions/mail/templates/retourConfirmationTemplate.js'


class CommunicationSettings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            useVeloydTrackTraceTemplate: props.user.settings.parcels.useVeloydTrackTraceTemplate,
            view: 'trackTrace'
        }
    }

    componentDidMount() {
        if (this.props?.location?.state?.view) {
            this.setState({view: this.props.location.state.view})
        }
    }

    onChangeValue(path, event) {
        const user = {...this.props.user}

        _.set(user.settings, path, event.target.value)

        this.props.onChange(user)
    }

    onChangeChecked(path, event) {
        const user = {...this.props.user}

        _.set(user.settings, path, event.target.checked)

        this.props.onChange(user, path)
    }

    onChangeFileUpload(path, fileName, value) {
        const user = {...this.props.user}

        _.set(user.settings, path, value)

        this.props.onChange(user, path)
    }

    render() {
        const {reseller, user} = this.props
        const {view} = this.state

        const returnsEnabled = _.some(_.keys(reseller.settings.parcels.products?.Retour), (carrier) => {
            return reseller.settings.parcels.products.Retour[carrier]
        })

        return (
            <div style={{display: 'flex'}}>
                <Panel style={{width: 600, padding: 20}}>
                    <H3>Email notificaties</H3>

                    <Tabs value={view} onChange={(event) => this.setState({view: event.target.value})}>
                        <Tab value='trackTrace'>Track & Trace</Tab>
                        <Tab value='cancelEmail'>Geannuleerd</Tab>
                        {returnsEnabled && <Tab value='retourConfirmation'>Retourbevestiging</Tab>}
                    </Tabs>

                    {view === 'trackTrace' &&
                        <>
                            <Toggle
                                label='Verstuur Track & Trace emails'
                                checked={user.settings.parcels.sendTTMails}
                                onChange={this.onChangeChecked.bind(this, 'parcels.sendTTMails')}
                            />

                            {!user.settings.parcels.sendTTMails &&
                                <Alert variant="info" style={{marginTop: -10, marginBottom: 30}}>Het versturen van Track & Trace email staat uit.</Alert>
                            }

                            <Input
                                label='Afzender naam'
                                placeholder={user.name}
                                value={user.settings.parcels.trackTraceSenderName}
                                onChange={this.onChangeValue.bind(this, 'parcels.trackTraceSenderName')}
                            />

                            <Input
                                label='Afzender emailadres'
                                placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                                value={user.settings.parcels.trackTraceReplyToEmail}
                                onChange={this.onChangeValue.bind(this, 'parcels.trackTraceReplyToEmail')}
                            />

                            <Select
                                noPlaceholder
                                label='Track & Trace email versturen'
                                value={user.settings.parcels.trackTraceEmailTrigger || 'confirmed'}
                                onChange={this.onChangeValue.bind(this, 'parcels.trackTraceEmailTrigger')}
                            >
                                <option value='confirmed'>Na bevestigen</option>
                                <option value='sent'>Na versturen</option>
                            </Select>

                            <br/>
                            <br/>
                            <Editor
                                label='Onderwerp'
                                as='input'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder']}
                                value={user.settings.parcels.trackTraceEmailSubject}
                                onChange={this.onChangeValue.bind(this, 'parcels.trackTraceEmailSubject')}
                            />

                            {!this.state.useVeloydTrackTraceTemplate &&
                                <>
                                    <Toggle
                                        style={{marginTop: 30}}
                                        label='Gebruik het nieuwe template voor Track & Trace emails'
                                        checked={user.settings.parcels.useVeloydTrackTraceTemplate}
                                        onChange={this.onChangeChecked.bind(this, 'parcels.useVeloydTrackTraceTemplate')}
                                    />
                                    {!user.settings.parcels.useVeloydTrackTraceTemplate &&
                                    <Alert variant="info" style={{marginTop: -10, marginBottom: 30}}>Wij adviseren om deze optie aan te zetten voor verbeterde Track & Trace emails.</Alert>
                                    }
                                </>
                            }

                            {!user.settings.parcels.useVeloydTrackTraceTemplate &&
                                <MailTemplate
                                    settings={user.settings}
                                    value={user.settings.parcels.trackTraceEmailTemplate}
                                    onChange={this.onChangeValue.bind(this, 'parcels.trackTraceEmailTemplate')}
                                    tags={['datum', 'naam', 'tav', 'straat', 'straat2', 'postcode', 'plaats', 'land', 'referentie', 'vervoerder', 'trackTraceCode', 'trackTraceLink', 'opties']}
                                />
                            }

                            {user.settings.parcels.useVeloydTrackTraceTemplate &&
                                <Editor
                                    label='Bericht'
                                    tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', user.settings.parcels.returnPortalActive && 'retourlink']}
                                    value={user.settings.parcels.trackTraceEmailBody}
                                    onChange={this.onChangeValue.bind(this, 'parcels.trackTraceEmailBody')}
                                />
                            }
                        </>
                    }

                    {view === 'cancelEmail' &&
                        <>
                            <Toggle
                                label='Verstuur geannuleerd email'
                                checked={user.settings.parcels.sendCancelEmails}
                                onChange={this.onChangeChecked.bind(this, 'parcels.sendCancelEmails')}
                            />

                            <Input
                                label='Afzender naam'
                                placeholder={user.name}
                                value={user.settings.parcels.cancelEmailSenderName}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailSenderName')}
                            />

                            <Input
                                label='Afzender emailadres'
                                placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                                value={user.settings.parcels.cancelEmailReplyToEmail}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailReplyToEmail')}
                            />

                            <br/>
                            <br/>
                            <Editor
                                label='Onderwerp'
                                as='input'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                                value={user.settings.parcels.cancelEmailSubject}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailSubject')}
                            />

                            <Editor
                                label='Bericht'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                                value={user.settings.parcels.cancelEmailBody}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailBody')}
                            />
                        </>
                    }

                    {view === 'retourConfirmation' &&
                    <>
                        <Toggle
                            label='Verstuur bevestigings mail bij retour'
                            checked={user.settings.parcels.sendRetourConfirmationEmails}
                            onChange={this.onChangeChecked.bind(this, 'parcels.sendRetourConfirmationEmails')}
                        />

                        <Input
                            label='Afzender naam'
                            placeholder={user.name}
                            value={user.settings.parcels.retourConfirmationEmailSenderName}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailSenderName')}
                        />

                        <Input
                            label='Afzender emailadres'
                            placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                            value={user.settings.parcels.retourConfirmationEmailReplyToEmail}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailReplyToEmail')}
                        />

                        <br/>
                        <br/>
                        <Editor
                            label='Onderwerp'
                            as='input'
                            tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                            value={user.settings.parcels.retourConfirmationEmailSubject}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailSubject')}
                        />

                        <Editor
                            label='Bericht'
                            tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                            value={user.settings.parcels.retourConfirmationEmailBody}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailBody')}
                        />
                    </>
                    }
                </Panel>

                {user.settings.parcels.useVeloydTrackTraceTemplate && view === 'trackTrace' &&
                    <div style={{width: 650, marginLeft: 60}}>
                        <EmailPreview
                            type='trackTrace'
                            html={trackTraceEmailTemplate({
                                logo: user.settings.logo || '/images/logoPlaceholder.png',
                                message: user.settings.parcels.trackTraceEmailBody?.replace(/\n/g, '<br/>') || '',
                                color: user.settings.color || Colors.brandColor,
                                parcel: {
                                    customerName: user.name,
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                },
                                addressHeader: 'Bezorgadres',
                                address: {
                                    name: 'John Doe',
                                    attention: '',
                                    street: 'Kastanjelaan',
                                    nr: '400',
                                    addition: '',
                                    street2: '',
                                    postalCode: '5616 LZ',
                                    city: 'Eindhoven',
                                    country: 'NL'
                                }
                            })}
                        />
                    </div>
                }

                {view === 'cancelEmail' &&
                    <div style={{width: 650, marginLeft: 60}}>
                        <EmailPreview
                            type='cancelEmail'
                            html={cancelEmailTemplate({
                                logo: user.settings.logo || '/images/logoPlaceholder.png',
                                message: user.settings.parcels.cancelEmailBody?.replace(/\n/g, '<br/>') || '',
                                color: user.settings.color || Colors.brandColor,
                                parcel: {
                                    customerName: user.name,
                                    address: {
                                        name: 'John Doe',
                                        attention: '',
                                        street: 'Kastanjelaan',
                                        nr: '400',
                                        addition: '',
                                        street2: '',
                                        postalCode: '5616 LZ',
                                        city: 'Eindhoven',
                                        country: 'NL'
                                    },
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                }

                            })}
                        />
                    </div>
                }

                {view === 'retourConfirmation' &&
                    <div style={{width: 650, marginLeft: 60}}>
                        <EmailPreview
                            type='retourConfirmation'
                            html={retourConfirmationTemplate({
                                logo: user.settings.logo || '/images/logoPlaceholder.png',
                                message: user.settings.parcels.retourConfirmationEmailBody?.replace(/\n/g, '<br/>') || '',
                                color: user.settings.color || Colors.brandColor,
                                parcel: {
                                    customerAddress: user.settings.parcels.customReturnAddress ?
                                        user.settings.parcels.returnAddress :
                                        user.address,
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                }
                            })}
                        />
                    </div>
                }

            </div>
        )
    }
}

export default (CommunicationSettings)
